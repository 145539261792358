import { TextField as MUITextField, TextFieldProps } from "@mui/material";

type Size = "small" | "medium" | "large";

const borderRadiusVariants = {
  small: "4px",
  medium: "8px",
  large: "16px",
};

const heightVariants = {
  small: "32px",
  medium: "48px",
  large: "64px",
};

export const TextField = (props: TextFieldProps & { size?: Size }) => {
  const borderRadius = borderRadiusVariants[props.size ?? "medium"];
  const height = heightVariants[props.size ?? "medium"];
  return (
    <MUITextField
      {...props}
      FormHelperTextProps={{
        sx: {
          position: "absolute",
          bottom: {
            small: -19,
            medium: -20,
            large: -25,
          }[props.size ?? "medium"],
        },
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: borderRadius,
          bgcolor: "white",
          height: props.multiline ? "auto" : height,
        },
        "& [data-shrink=false]": {
          top: "-4px",
        },
        "& .MuiFormLabel-root:not(.Mui-focused)": {
          color: "text.secondary",
        },
        ...props.sx,
      }}
    />
  );
};
