import { Box } from "@mui/material";
import algoliasearch from "algoliasearch/lite";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  Configure,
  InstantSearch,
  useHits,
  useInstantSearch,
  useRelatedProducts,
} from "react-instantsearch";
import { Hit } from "react-instantsearch-core";
import { CommonDrawer } from "shared-components";
import useComponentDidMount from "../../../../../../hooks/useComponentDidMount";
import { Hit as CustomHit } from "../../../../../marketplace/components/Hit";
import "./recommendations.scss";
import SingleProfile from "../ProfileSearch/SingleProfile";
import RecommendationsSlider from "./HitsSlider";

const INDEX_NAME = `Site_${process.env.REACT_APP_APP_ENV}`;

const searchClient =
  process.env.REACT_APP_ALGOLIASEARCH_APP_ID &&
  process.env.REACT_APP_ALGOLIASEARCH_READ_KEY_PUBLIC
    ? algoliasearch(
        process.env.REACT_APP_ALGOLIASEARCH_APP_ID,
        process.env.REACT_APP_ALGOLIASEARCH_READ_KEY_PUBLIC
      )
    : null;

const Recommendations = React.memo(function Recommendations({
  companyId,
  objectIDs,
}: {
  companyId: number;
  objectIDs: string[];
}) {
  const [filters, setFilters] = useState(`recommendations:${companyId}`);
  const [hit, setHit] = useState<Hit<CustomHit> | null>(null);

  const [favorites, setFavorites] = useState<number[]>([]);
  useEffect(() => {
    axios.get("/api/v1/sites/favorites").then((response) => {
      setFavorites(response.data);
    });
  }, []);

  if (!searchClient) {
    return null;
  }

  return (
    <InstantSearch indexName={INDEX_NAME} searchClient={searchClient}>
      <Configure
        filters={filters}
        facets={[
          "audience_primary_age",
          "category_names",
          "favorites",
          "formats",
          "has_discounts?",
          "in_demand?",
          "is_verified?",
          "list_size",
          "picked",
          "sponsored_email_dates",
        ]}
      />
      <Box className="recommendations-wrapper">
        <CommonDrawer open={!!hit} onClose={() => setHit(null)}>
          <SingleProfile hit={hit} />
        </CommonDrawer>
        <Box className="publishers">
          {objectIDs.length ? (
            <RelatedProductsContent
              objectIDs={objectIDs}
              favorites={favorites}
              setHit={setHit}
            />
          ) : (
            <RecommendationsContent
              setHit={setHit}
              setFilters={setFilters}
              favorites={favorites}
            />
          )}
        </Box>
      </Box>
    </InstantSearch>
  );
});

function RelatedProductsContent({
  objectIDs,
  favorites,
  setHit,
}: {
  objectIDs: string[];
  favorites: number[];
  setHit: (hit: Hit<CustomHit> | null) => void;
}) {
  const { items } = useRelatedProducts({
    objectIDs,
  }) as { items: Hit<CustomHit>[] };
  const { status } = useInstantSearch();

  return (
    <RecommendationsSlider
      onHitClick={setHit}
      items={items.slice(0, 10)}
      isLoading={status === "loading"}
      title="Recommendations"
      favorites={favorites}
    />
  );
}

function RecommendationsContent({
  setFilters,
  favorites,
  setHit,
}: {
  setFilters: (filters: string) => void;
  favorites: number[];
  setHit: (slug: Hit<CustomHit> | null) => void;
}) {
  const { items } = useHits() as { items: Hit<CustomHit>[] };
  const { status } = useInstantSearch();
  const itemsRef = useRef(items);

  useEffect(() => {
    itemsRef.current = items;
  }, [items]);

  const resetFilters = () => {
    if (itemsRef.current.length === 0) {
      setFilters("");
    }
  };

  useComponentDidMount(() => {
    // TODO: if we have a lot of users without recommendations on prod,
    //  we should check status;
    //  It requires more effort to implement it. useRef e.t.c.
    setTimeout(resetFilters, 2_000);
  });

  return (
    <RecommendationsSlider
      onHitClick={setHit}
      items={items.slice(0, 10)}
      isLoading={status === "loading"}
      title="Recommendations"
      favorites={favorites}
    />
  );
}

export default Recommendations;
