import { Box, Divider } from "@mui/material";
import { Profile, SponsorshipOption } from "../../../types";
import SponsorshipRow from "./SponsorshipRow";
import GridRow from "../../common/GridRow";
import { getProfileGridCells } from "./gridItems";

// Types
type ProfileRowProps = {
  profile: Profile;
  onRemove: (profile: Profile) => void;
};

// Styles
const styles = {
  divider: {
    borderColor: "#F2F3F7",
  },
};

function ProfileRow({ profile, onRemove }: ProfileRowProps) {
  const gridCells = getProfileGridCells(profile, onRemove);

  return (
    <>
      <GridRow cells={gridCells} />
      {profile.sponsorshipOptions?.map(
        (sponsorship: SponsorshipOption, index: number) => (
          <Box key={sponsorship.id}>
            <SponsorshipRow
              sponsorship={sponsorship}
              profileSlug={profile.slug}
            />
            {index !== profile.sponsorshipOptions!.length - 1 && (
              <Divider sx={styles.divider} />
            )}
          </Box>
        )
      )}
      <Divider />
    </>
  );
}

export default ProfileRow;
