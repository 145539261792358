import { format } from "date-fns";
import { isNil } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { MediaPlan, MediaPlanField, MediaPlanStatus } from "../../types";
import EmptyState from "./EmptyState";
import MediaPlansTableBody, {
  MediaPlansTableItem,
} from "./MediaPlansTableBody";
import MediaPlansTableHeader from "./MediaPlansTableHeader";
import { PriceInfo } from "./PriceInfo";
import { Stack } from "@mui/material";

export default function MediaPlansTable({
  items,
  loading,
  status,
  setStatus,
}: {
  items: MediaPlan[];
  loading: boolean;
  status: MediaPlanStatus;
  setStatus: (status: MediaPlanStatus) => void;
}) {
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<MediaPlanField>();
  const [sortDirection, setSortDirection] = useState<
    "asc" | "desc" | undefined
  >(undefined);

  useEffect(() => {
    setSortBy(undefined);
    setSearch("");
  }, [status, items]);

  const toggleSortDirection = () => {
    switch (sortDirection) {
      case "asc":
        setSortDirection(undefined);
        break;
      case "desc":
        setSortDirection("asc");
        break;
      default:
        setSortDirection("desc");
    }
  };

  useEffect(() => {
    setSortDirection("desc");
  }, [sortBy, setSortDirection]);

  const filteredPlans: MediaPlansTableItem[] = useMemo(() => {
    const filteredItems = items.filter((plan: MediaPlan) =>
      plan.name.toLowerCase().includes(search.toLowerCase())
    );

    const direction = sortDirection === "asc" ? 1 : -1;

    const currentSortBy = !!sortBy && !!sortDirection ? sortBy : "createdAt";

    const sortedItems = filteredItems.sort((a: MediaPlan, b: MediaPlan) => {
      if (isNil(a[currentSortBy]) || isNil(b[currentSortBy])) {
        return 0;
      }

      if (a[currentSortBy]! < b[currentSortBy]!) {
        return -1 * direction;
      }
      if (a[currentSortBy]! > b[currentSortBy]!) {
        return 1 * direction;
      }
      return 0;
    });

    switch (status) {
      case "draft": {
        return sortedItems.map((mediaPlan: MediaPlan) => ({
          uuid: mediaPlan.uuid,
          name: mediaPlan.name,
          // TODO: data should have common model with header
          data: [
            mediaPlan.sponsorshipsCount,
            <PriceInfo
              price={mediaPlan.price}
              priceWithoutDiscount={mediaPlan.priceWithoutDiscount}
            />,
          ],
        }));
      }
      case "in_progress": {
        return sortedItems.map((mediaPlan: MediaPlan) => ({
          uuid: mediaPlan.uuid,
          name: mediaPlan.name,
          data: [
            `${mediaPlan.completedSponsorships} / ${mediaPlan.sponsorshipsCount}`,
            <PriceInfo
              price={mediaPlan.price}
              priceWithoutDiscount={mediaPlan.priceWithoutDiscount}
            />,
            mediaPlan.actionsRequired,
          ],
        }));
      }
      case "completed": {
        return sortedItems.map((mediaPlan: MediaPlan) => ({
          uuid: mediaPlan.uuid,
          name: mediaPlan.name,
          data: [
            format(mediaPlan.bookingDate, "MMM do yyyy"),
            <PriceInfo
              price={mediaPlan.price}
              priceWithoutDiscount={mediaPlan.priceWithoutDiscount}
            />,
          ],
        }));
      }
    }
  }, [items, search, sortBy, sortDirection, status]);

  return (
    <Stack minWidth="1000px" overflow={{ xs: "auto", md: "unset" }} flex={1}>
      <MediaPlansTableHeader
        status={status}
        search={search}
        setSearch={setSearch}
        sortBy={sortBy}
        sortDirection={sortDirection}
        setSortBy={setSortBy}
        toggleSortDirection={toggleSortDirection}
      />
      {!loading && items.length > 0 && (
        <MediaPlansTableBody items={filteredPlans} />
      )}
      {!loading && items.length === 0 && (
        <EmptyState status={status} setStatus={setStatus} />
      )}
    </Stack>
  );
}
