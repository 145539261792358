import { FC } from "react";
import { SuccessMessageDialog, WarningMessageDialog } from "shared-components";
import { Profile } from "../../../types";

interface RemoveProfileModalProps {
  profile: Profile;
  modalType: "confirm" | "success" | null;
  setModalType: (type: "confirm" | "success" | null) => void;
  handleRemove: (profile: Profile) => void;
}

const RemoveProfileModal: FC<RemoveProfileModalProps> = ({
  profile,
  modalType,
  setModalType,
  handleRemove,
}) => {
  const isSuccess = modalType === "success";

  if (!modalType) return null;

  return isSuccess ? (
    <SuccessMessageDialog
      messageDescription={`All sponsorships of ${profile.name} have been removed from your media plan.`}
      onClose={() => setModalType(null)}
      messageTitle={"Newsletter Removed"}
    />
  ) : (
    <WarningMessageDialog
      messageDescription={`This will remove all sponsorships of ${profile.name} from your media plan.`}
      onClose={() => setModalType(null)}
      messageTitle={"Remove Newsletter?"}
      onSubmit={() => handleRemove(profile)}
      caption="Remove"
    />
  );
};

export default RemoveProfileModal;
