import { InfoOutlined } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { currencyFormatter } from "utils/formatters";

export function PriceDisplay({
  price,
  priceWithoutDiscount,
  oldPrice,
  priceChanged = false,
  bold = false,
}: {
  price: number;
  priceWithoutDiscount?: number;
  priceChanged?: boolean;
  bold?: boolean;
  oldPrice?: number | null;
}) {
  const previousPriceToShow =
    oldPrice && Number(oldPrice) < Number(price)
      ? oldPrice
      : priceWithoutDiscount && Number(priceWithoutDiscount) > Number(price)
      ? priceWithoutDiscount
      : null;

  return (
    <Grid container>
      <Grid
        item
        xs={10}
        textAlign="end"
        fontWeight={bold ? "bold" : "normal"}
        fontSize={16}
        sx={{
          ...(oldPrice &&
            Number(oldPrice) < Number(price) && { color: "error.main" }),
        }}
      >
        {previousPriceToShow && (
          <Typography
            component="span"
            sx={{ textDecoration: "line-through", mr: 1 }}
            color="grey.400"
          >
            {currencyFormatter(previousPriceToShow, 0)}
          </Typography>
        )}
        {currencyFormatter(price, 0)}
      </Grid>
      <Grid item xs={2}>
        {priceChanged && (
          <InfoOutlined sx={{ fontSize: 16 }} color="disabled" />
        )}
      </Grid>
    </Grid>
  );
}
