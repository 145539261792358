import Cookies from "js-cookie";
import { constants } from "shared-components";
import store from "store2";

const { STEP_SIGN_IN, REQUEST_SIGNUP_SITE_DATA } = constants;

export function getPathForRedirectToProfilePage(): string | undefined {
  const data = store.get(REQUEST_SIGNUP_SITE_DATA);

  if (data?.step !== STEP_SIGN_IN || !data?.path) {
    return;
  }

  return data.path;
}

export function isAdmin() {
  return !!Cookies.get("admin");
}

export function isAssumed() {
  return !!Cookies.get("asmd");
}
