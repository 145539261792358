import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { useRef, useState, useCallback } from "react";
import { Hit } from "react-instantsearch-core";
import { useParams } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import { Icon } from "shared-components";
import HitComponent, {
  Hit as CustomHit,
} from "../../../../../marketplace/components/Hit";

interface HitsSliderProps {
  items: Hit<CustomHit>[];
  isLoading: boolean;
  title?: string;
  favorites: number[];
  onHitClick?: (hit: Hit<CustomHit> | null) => void;
}

function HitsSlider({
  items,
  isLoading,
  title,
  favorites,
  onHitClick,
}: HitsSliderProps) {
  const { mediaPlanId } = useParams();
  const sliderRef = useRef<Slider | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const placeholderItems = Array.from({ length: 5 }, (_, index) => (
    <Box key={index}>
      <Skeleton
        variant="rectangular"
        width="440px"
        height="234px"
        animation="wave"
        sx={{ borderRadius: "8px", mr: "16px" }}
      />
    </Box>
  ));

  const renderHit = useCallback(
    (hit: Hit<CustomHit>) => (
      <div
        key={hit.objectID}
        onClick={(e) => {
          if (!onHitClick) return;
          e.preventDefault();
          onHitClick?.(hit);
        }}
      >
        <HitComponent
          hit={hit as CustomHit}
          favorites={favorites}
          key={hit.objectID}
          href={`/sites/${hit.slug}?mp_id=${mediaPlanId}`}
          target="_blank"
          wrapEntireContent={false}
        />
      </div>
    ),
    [mediaPlanId, favorites, onHitClick]
  );

  const settings: Settings = {
    dots: false,
    arrows: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 7_000,
    pauseOnHover: true,
    beforeChange: (_: number, next: number) => setCurrentSlide(next),
  };

  const handleNext = useCallback(() => {
    sliderRef.current?.slickNext();
  }, []);

  const handlePrevious = useCallback(() => {
    sliderRef.current?.slickPrev();
  }, []);

  const handleGoToSlide = useCallback((index: number) => {
    sliderRef.current?.slickGoTo(index);
  }, []);

  const totalSlides = isLoading ? placeholderItems.length : items.length;

  return (
    <Stack direction={title ? "column" : "column-reverse"}>
      <Stack
        direction="row"
        justifyContent={title ? "space-between" : "center"}
        alignItems="center"
        width="100%"
        spacing={2}
        sx={{ mb: 2 }}
      >
        {title && (
          <Typography
            variant="h6"
            sx={{ fontSize: "24px", color: "text.primary", mb: "16px" }}
          >
            {title}
          </Typography>
        )}
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton
            onClick={handlePrevious}
            disabled={isLoading}
            size="small"
            sx={{
              color: isLoading ? "grey.300" : "text.primary",
            }}
          >
            <Icon name="left" width={25} height={25} weight={500} />
          </IconButton>
          {Array.from({ length: totalSlides }).map((_, index) => (
            <IconButton
              key={index}
              onClick={() => handleGoToSlide(index)}
              disabled={isLoading}
              sx={{
                padding: 0,
                minWidth: "auto",
                color: index === currentSlide ? "primary.dark" : "#B5CFFB",
              }}
            >
              <FiberManualRecordIcon sx={{ fontSize: "12px" }} />
            </IconButton>
          ))}
          <IconButton
            onClick={handleNext}
            disabled={isLoading}
            size="small"
            sx={{ color: isLoading ? "grey.300" : "text.primary" }}
          >
            <Icon name="right" width={25} height={25} weight={500} />
          </IconButton>
        </Stack>
      </Stack>
      <Slider className="list" ref={sliderRef} {...settings}>
        {isLoading ? placeholderItems : items.map(renderHit)}
      </Slider>
    </Stack>
  );
}

export default HitsSlider;
