import { Grid, Typography } from "@mui/material";

import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { currencyFormatter } from "utils/formatters";

export function PriceInfo({
  price,
  priceWithoutDiscount,
  priceChanged = false,
}: {
  price: number;
  priceWithoutDiscount?: number;
  priceChanged?: boolean;
}) {
  return (
    <Grid container>
      <Grid item xs={10} textAlign="end">
        {!!priceWithoutDiscount && price < priceWithoutDiscount && (
          <Typography
            component="span"
            sx={{ textDecoration: "line-through", mr: 1 }}
            color="grey.400"
          >
            {currencyFormatter(priceWithoutDiscount, 0)}
          </Typography>
        )}
        {currencyFormatter(price, 0)}
      </Grid>
      <Grid item xs={2}>
        {priceChanged && (
          <InfoOutlined sx={{ fontSize: 16 }} color="disabled" />
        )}
      </Grid>
    </Grid>
  );
}
