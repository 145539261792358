import React from "react";
import { ConfirmationMessageDialog } from "./ConfirmationMessageDialog";

export type SuccessMessageDialogProps = {
  messageTitle: string;
  messageDescription: string;
  onClose: () => void;
  caption?: string;
  withCloseButton?: boolean;
};

export function SuccessMessageDialog({
  messageTitle,
  messageDescription,
  onClose,
  caption = "OK",
  withCloseButton = true,
}: SuccessMessageDialogProps) {
  return (
    <ConfirmationMessageDialog
      open={true}
      messageDescription={messageDescription}
      messageTitle={messageTitle}
      variant="success"
      onClose={onClose}
      actions={
        withCloseButton
          ? [
              {
                caption,
                onClick: onClose,
                type: "submit",
              },
            ]
          : []
      }
    />
  );
}
