import { User, SegmentDataLayer, SpecWindow } from "../types";
import Cookies from "js-cookie";

// TODO: Unify for all packages and use https://www.npmjs.com/package/@segment/analytics-next
// https://github.com/segmentio/react-example/tree/main/src/examples/analytics-quick-start
// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#basic-tracking-methods
function trackWithSegments() {
  return !Cookies.get("admin") && "analytics" in window;
}

export function segmentsIdentify(userData: User, responseData: any) {
  if (!trackWithSegments()) {
    return;
  }

  const { userType, firstName, lastName, email, companyName } = userData;
  const { ro_id: id, created_at: created_ts } = responseData;

  const segmentDataLayer: SegmentDataLayer = {
    state: "logged in",
    email: email!,
    first_name: firstName,
    last_name: lastName,
    firstName,
    lastName,
    name: `${firstName} ${lastName}`,
    id,
    type: userType!,
    company_name: companyName!,
    company: companyName!,
    created: new Date(created_ts * 1_000).toISOString(),
    created_ts,
  };

  (window as SpecWindow).analytics.identify?.(id, segmentDataLayer);
}

export function segmentsTrack(eventName: string, data?: Record<string, any>) {
  if (!trackWithSegments()) {
    return;
  }

  if (!!data) {
    return (window as SpecWindow).analytics.track?.(eventName, data);
  }

  return (window as SpecWindow).analytics.track?.(eventName);
}

export function segmentsPageView() {
  if (!trackWithSegments()) {
    return;
  }

  return (window as SpecWindow).analytics.page?.();
}
