import React from "react";
import { Icon, IconChip } from "shared-components";

type SubscribersQtyProps = {
  subscribers: number | null;
};

export default function SubscribersQty({ subscribers }: SubscribersQtyProps) {
  return (
    <IconChip
      icon={<Icon name="subscribers" />}
      label={subscribers?.toLocaleString("en-US") ?? "n/a"}
      labelClassName="chip-label"
    />
  );
}
