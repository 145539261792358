import { FiberManualRecord } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

type SponsorshipErrorLabelProps = {
  error: string;
};
function SponsorshipErrorLabel({ error }: SponsorshipErrorLabelProps) {
  return (
    <Box mb={1}>
      {true && (
        <Typography variant="caption" color="text.secondary">
          <FiberManualRecord
            color="error"
            sx={{
              fontSize: "10px",
              lineHeight: "4px",
              mr: "4px",
              display: "inline",
            }}
          />
          {error}
        </Typography>
      )}
    </Box>
  );
}

export default SponsorshipErrorLabel;
