import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import noop from "lodash/noop";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { CommonDrawer, ConfirmationMessageDialog } from "shared-components";
import * as Yup from "yup";
import FormWrapper from "../../../../components/FormWrapper";
import { TextField } from "../../../../components/MUIWrappers";
import { LayoutContext } from "../../../../context";
import PriceInfoData from "./layout/PriceInfo/PriceInfoData";
import { prepareMediaPlan } from "../utils";
import { MediaPlanContext } from "../context";
import Notifications from "./common/Notification";

const formName = "book-media-plan";

export type ConfirmationProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  discountPrice: number;
  regularPrice: number;
  totalPrice: number;
  discountPercent?: number;
  sponsorshipsQty: number;
  mediaPlanName: string;
};

function sponsorshipsQtyString(sponsorshipsQty: number) {
  return sponsorshipsQty === 1
    ? `${sponsorshipsQty} Sponsorship`
    : `${sponsorshipsQty} Sponsorships`;
}

const validationSchema = Yup.object().shape({
  message: Yup.string().required("This field is required."),

  confirmed: Yup.boolean().oneOf(
    [true],
    "You must confirm that you understand the cancellation policy"
  ),
});

type ConfirmationFormProps = {
  onSubmit: () => void;
  onReject: () => void;
};

function ConfirmationForm({ onSubmit, onReject }: ConfirmationFormProps) {
  const { setIsDataLoading } = useContext(LayoutContext);
  const { setMediaPlan } = useContext(MediaPlanContext)!;
  const { mediaPlanId } = useParams();

  const initialMessageToPublishers =
    localStorage.getItem("lastMessageToPublishers") || "";

  return (
    <Formik
      initialValues={{
        message: initialMessageToPublishers,
        confirmed: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setIsDataLoading(true);
        localStorage.setItem("lastMessageToPublishers", values.message);

        axios
          .post(
            `/api/v1/advertiser/media_plans/${mediaPlanId}/book_sponsorships`,
            {
              message: values.message,
            }
          )
          .then((res) => {
            if (!res.data.booked) {
              setMediaPlan(prepareMediaPlan(res.data));
              return onReject();
            }

            onSubmit();
          })
          .catch(noop)
          .finally(() => setIsDataLoading(false));
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <FormWrapper onSubmit={handleSubmit} name={formName} id={formName}>
          <Divider sx={{ mb: 3 }} />
          <Typography sx={{ mb: 3 }}>
            Please share a few details about your brand and what you are
            planning to promote.
          </Typography>
          <Box sx={{ mb: 4 }}>
            <TextField
              fullWidth
              id="message"
              name="message"
              label="Message to publishers"
              placeholder="Write your message..."
              multiline
              rows={8}
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.message && Boolean(errors.message)}
              helperText={touched.message && errors.message}
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.confirmed}
                onChange={handleChange}
                color="primary"
                name="confirmed"
              />
            }
            sx={{
              color: "text.secondary",
              fontSize: "16px",
              py: 1,
              pr: 1,
              borderRadius: "8px",
              mx: 0,
              bgcolor:
                errors.confirmed && touched.confirmed
                  ? "#FCEFF0"
                  : "transparent",
            }}
            label="You understand this is a request for a sponsorship. If accepted by the publisher, you may not be able to cancel this request without paying a cancellation fee."
          />
        </FormWrapper>
      )}
    </Formik>
  );
}

function ConfirmationInfoRow({
  caption,
  value,
}: {
  caption: string;
  value: string;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mb: "20px",
        fontSize: "16px",
        alignItems: "center",
      }}
    >
      <Box sx={{ fontWeight: 700 }}>{caption}:</Box>
      <Box>{value}</Box>
    </Box>
  );
}

export default function Confirmation({
  open,
  onClose,
  onConfirm,
  title,
  regularPrice,
  discountPercent,
  discountPrice,
  totalPrice,
  sponsorshipsQty,
  mediaPlanName,
}: ConfirmationProps) {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const { pricesChanged } = useContext(MediaPlanContext)!;

  const handleConfirm = () => {
    onConfirm();
    setIsSuccessModalOpen(true);
  };

  const handleReject = () => {
    onClose();
  };

  const handleCloseSuccessModal = () => {
    window.location.href = "/advertiser/media_plans";
  };

  const handleNewMediaPlan = () => {
    window.location.href = "/advertiser/media_plans/new";
  };

  return (
    <>
      <CommonDrawer
        open={open}
        onClose={onClose}
        title={title}
        actions={[
          {
            caption: "Cancel",
            onClick: onClose,
            type: "button",
          },
          {
            caption: "Confirm",
            type: "submit",
            form: formName,
          },
        ]}
        dividers
      >
        <Box p={2}>
          <Notifications pricesChanged={pricesChanged} />
          <ConfirmationInfoRow
            caption="Total"
            value={sponsorshipsQtyString(sponsorshipsQty)}
          />
          <Divider sx={{ mb: 3 }} />
          <PriceInfoData
            discountPrice={discountPrice}
            regularPrice={regularPrice}
            discountPercent={discountPercent}
            totalPrice={totalPrice}
          />
          <ConfirmationForm onSubmit={handleConfirm} onReject={handleReject} />
        </Box>
      </CommonDrawer>

      {isSuccessModalOpen && (
        <ConfirmationMessageDialog
          onClose={() => setIsSuccessModalOpen(false)}
          open={isSuccessModalOpen}
          variant="success"
          messageTitle="Your Media Plan is Booked"
          messageDescription={
            <>
              <Typography variant="body1">
                All sponsorships in the {mediaPlanName} media plan are booked.
                Track their progress through your dashboard.
              </Typography>
              <Typography variant="body1">
                <br />
                We’ll inform you of any updates. Please act quickly when
                required.
              </Typography>
            </>
          }
          actions={[
            {
              caption: "My Dashboard",
              onClick: handleCloseSuccessModal,
            },
            {
              caption: "New Media Plan",
              onClick: handleNewMediaPlan,
              type: "submit",
            },
          ]}
        />
      )}
    </>
  );
}
