import { Stack } from "@mui/material";
import axios from "axios";
import { Paper } from "components/MUIWrappers";
import noop from "lodash/noop";
import { useContext, useEffect, useState } from "react";
import useComponentDidMount from "../../../hooks/useComponentDidMount";
import MediaPlansTable from "./components/MediaPlansTable";
import StatusToggle from "./components/StatusToggle";
import { MediaPlan, MediaPlanData, MediaPlanStatus } from "./types";
import { LayoutContext } from "context";

function prepareMediaPlans(mediaPlanData: MediaPlanData[]): MediaPlan[] {
  return mediaPlanData.map((item) => ({
    uuid: item.uuid,
    name: item.name,
    price: item.price,
    priceWithoutDiscount: item.price_without_discount,
    bookingDate: item.booking_date ? new Date(item.booking_date) : new Date(),
    createdAt: item.created_at ? new Date(item.created_at) : new Date(),
    status: item.status,
    completedSponsorships: item.completed_sponsorships,
    sponsorshipsCount: item.sponsorships_count,
    actionsRequired: item.actions_required,
  }));
}

export default function MediaPlans() {
  const { isDataLoading, setIsDataLoading } = useContext(LayoutContext);
  const [mediaPlans, setMediaPlans] = useState<MediaPlan[]>([]);
  const [mediaPlansFiltered, setMediaPlansFiltered] = useState<MediaPlan[]>([]);
  const [status, setStatus] = useState<MediaPlanStatus>("draft");

  useComponentDidMount(() => {
    setIsDataLoading(true);
    axios
      .get("/api/v1/advertiser/media_plans")
      .then((response) => {
        setMediaPlans(prepareMediaPlans(response.data));
      })
      .catch(noop)
      .finally(() => setIsDataLoading(false));
  });

  useEffect(() => {
    const mediaPlanByStatus = mediaPlans.filter((mediaPlan: MediaPlan) => {
      return mediaPlan.status === status;
    });

    setMediaPlansFiltered(mediaPlanByStatus);
  }, [mediaPlans, status, setMediaPlansFiltered]);

  return (
    <Paper>
      <Stack
        sx={{
          //TODO: add breadcrumbs
          minHeight: { xs: "calc(100svh - 95px)", md: "calc(100svh - 145px)" },
          maxHeight: { xs: "calc(100svh - 95px)", md: "unset" },
          position: "relative",
          overflowY: { xs: "hidden", md: "unset" },
          overflowX: "auto",
        }}
      >
        <StatusToggle value={status} onChange={setStatus} />
        <MediaPlansTable
          items={mediaPlansFiltered}
          status={status}
          setStatus={setStatus}
          loading={isDataLoading}
        />
      </Stack>
    </Paper>
  );
}
