import { Add } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { Button } from "shared-components";
import { MediaPlanStatus } from "../../types";
import { format } from "date-fns";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LayoutContext } from "context";
import { useContext } from "react";
import { noop } from "lodash";

type EmptyStateProps = {
  status: MediaPlanStatus;
  setStatus: (status: MediaPlanStatus) => void;
};

const statusConfig = {
  draft: {
    title: "Create Your First Media Plan",
    description:
      "Create your first media plan to easily book multiple sponsorships at once.",
    showManageDraft: false,
  },
  in_progress: {
    title: "No Active Media Plans",
    description:
      "You don’t have any active media plans. Start a new one or finalize a draft to proceed.",
    showManageDraft: true,
  },
  completed: {
    title: "No Completed Media Plans",
    description:
      "Once you complete a media plan, you’ll see it here. Start planning or finalize a draft to proceed.",
    showManageDraft: true,
  },
};

function EmptyState({ status, setStatus }: EmptyStateProps) {
  const { setIsDataLoading } = useContext(LayoutContext);
  const navigate = useNavigate();
  const { title, description, showManageDraft } = statusConfig[status];

  const createNewMediaPlanButton = (
    <Button
      startIcon={<Add />}
      variant="outlined"
      onClick={() => {
        setIsDataLoading(true);
        const newMediaPlanName = format(new Date(), "MM/dd/yyyy HH:mm:ss");
        axios
          .post("/api/v1/advertiser/media_plans", {
            name: newMediaPlanName,
          })
          .then((response) => {
            const { uuid } = response.data;
            navigate(`/advertiser/media_plans/${uuid}`);
          })
          .catch(noop)
          .finally(() => {
            setIsDataLoading(false);
          });
      }}
    >
      Create New Media Plan
    </Button>
  );

  return (
    <Stack
      height="100%"
      justifyContent="center"
      alignItems="center"
      flex={1}
      gap={2}
    >
      <Typography
        fontSize="16px"
        color="text.secondary"
        fontWeight="bold"
        textAlign="center"
      >
        {title}
      </Typography>
      <Typography fontSize="16px" color="text.secondary" textAlign="center">
        {description}
      </Typography>
      {showManageDraft ? (
        <Stack direction="row" spacing={1}>
          {createNewMediaPlanButton}
          <Button onClick={() => setStatus("draft")}>
            Manage Drafted Plans
          </Button>
        </Stack>
      ) : (
        createNewMediaPlanButton
      )}
    </Stack>
  );
}

export default EmptyState;
