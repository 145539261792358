const REQUEST_SIGNUP_SITE_DATA = "pvd-request-signup-data";
const STEP_COMPANY_DATA = "companyData";
const STEP_READY_FOR_BOOK = "readyForBook";
const STEP_SIGN_IN = "signIn";

export const constants = {
  REQUEST_SIGNUP_SITE_DATA,
  STEP_COMPANY_DATA,
  STEP_READY_FOR_BOOK,
  STEP_SIGN_IN,
};
