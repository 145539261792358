import { Sponsorship } from "../../../types";
import GridRow from "../../common/GridRow";
import { getDatesRowCells } from "./gridItems";

function DatesRow({ sponsorship }: { sponsorship: Sponsorship }) {
  const today = new Date();
  const deadlineObj = sponsorship.deadline
    ? new Date(sponsorship.deadline * 1000)
    : 0;
  const isOutOfDateRange = deadlineObj
    ? (deadlineObj.getTime() - today.getTime()) / (1000 * 60 * 60 * 24) < 3
    : false;

  return (
    <GridRow
      cells={getDatesRowCells(sponsorship, today)}
      containerSx={{
        backgroundColor: isOutOfDateRange ? "#FEF7F8" : "inherit",
        "&:hover": {
          bgcolor: isOutOfDateRange ? "#FCEFF0" : "#F8FAFF",
        },
        fontSize: 16,
        color: sponsorship.status === "Canceled" ? "grey.400" : "inherit",
      }}
    />
  );
}

export default DatesRow;
