import { Add, InfoOutlined } from "@mui/icons-material";
import { CircularProgress, Stack, SxProps, Theme } from "@mui/material";
import { Button, Icon } from "shared-components";
import Tooltip from "components/Tooltip";
import { useState } from "react";

const iconSx = {
  opacity: { xs: 1, md: 0 },
  transition: "opacity 0.3s",
  cursor: "pointer",
  color: "text.disabled",
  "&:hover": { color: "primary.main" },
};

interface RemoveButtonProps {
  onClick: () => void;
  className?: string;
}

interface AddButtonProps {
  isBooked: boolean;
  onAdd: () => void;
}

const defaultSx: SxProps<Theme> = {
  alignItems: "center",
  opacity: { xs: 1, md: 0 },
  transition: "opacity 0.3s",
  cursor: "pointer",
  color: "#BCC4DA",
  "&:hover": { color: "primary.main" },
};

const buttonSx = { minWidth: 0, width: "32px" };

function RemoveButton({
  onClick,
  className = "action-close-btn",
}: RemoveButtonProps) {
  return (
    <Stack
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      sx={{ ...defaultSx, ...iconSx } as SxProps<Theme>}
      className={className}
    >
      <Icon
        name="close"
        fill="currentColor"
        width={20}
        height={20}
        weight={700}
      />
    </Stack>
  );
}

function AddButton({ isBooked, onAdd }: AddButtonProps) {
  return (
    <Button
      disabled={isBooked}
      variant="outlined"
      disableElevation
      sx={buttonSx}
      onClick={(e) => {
        e.stopPropagation();
        onAdd();
      }}
    >
      <Add />
    </Button>
  );
}

function InfoIcon({ description }: { description: string | null }) {
  return (
    <Tooltip
      title={description || ""}
      disableInteractive
      arrow
      placement="right"
      enterTouchDelay={0}
      leaveTouchDelay={10000}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <InfoOutlined className="sponsorship-info" sx={iconSx} fontSize="small" />
    </Tooltip>
  );
}

function AcceptButton({ onClick }: { onClick: () => Promise<any> }) {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  return (
    <Button
      startIcon={
        loading ? <CircularProgress size={12} color="inherit" /> : null
      }
      variant="outlined"
      onClick={handleClick}
      disabled={loading}
      sx={{ color: "#6D7379" }}
    >
      Accept
    </Button>
  );
}

export { AddButton, RemoveButton, InfoIcon, AcceptButton };
