import React, { createContext, useState, ReactNode, useEffect } from "react";
import noop from "lodash/noop";
import { InboxStatus, IPavedFee, User } from "types";

export interface IUserContext {
  userData: User;
  pavedFee: IPavedFee;
  inboxStatus: InboxStatus;
  setUserData: (value: User) => void;
  setPavedFee: (fee: IPavedFee) => void;
  setInboxStatus: (status: InboxStatus) => void;
  breadcrumbLabel?: string;
  setBreadcrumbLabel: (label: string) => void;
}

const DEFAULT_STATE: IUserContext = {
  userData: {
    firstName: "",
    lastName: "",
    companyName: "",
    createdAt: new Date(),
    isAdmin: false,
    has_booker_access: false,
    has_ad_network_access: false,
    has_marketplace_access: false,
    userMenu: [],
    uuid: "",
    ro_id: null,
    companyId: null,
  },
  pavedFee: {
    dedicated_email: 0,
    sponsored_email: 0,
    sponsored_post: 0,
  },
  setUserData: noop,
  setPavedFee: noop,
  inboxStatus: {
    has_active_chats: false,
    unread_count: 0,
  },
  setInboxStatus: noop,
  breadcrumbLabel: "",
  setBreadcrumbLabel: noop,
};

export const UserContext = createContext(DEFAULT_STATE);

export interface IUserProviderProps {
  children?: ReactNode;
  initialState?: IUserContext;
}

export const UserProvider = ({
  children,
  initialState,
}: IUserProviderProps) => {
  const [userData, setUserData] = useState(DEFAULT_STATE.userData);
  const [pavedFee, setPavedFee] = useState(DEFAULT_STATE.pavedFee);
  const [inboxStatus, setInboxStatus] = useState(DEFAULT_STATE.inboxStatus);
  const [breadcrumbLabel, setBreadcrumbLabel] = useState(
    DEFAULT_STATE.breadcrumbLabel
  );

  useEffect(() => {
    if (initialState?.userData) {
      setUserData(initialState.userData);
    }
    if (initialState?.pavedFee) {
      setPavedFee(initialState.pavedFee);
    }
  }, [initialState]);

  return (
    <UserContext.Provider
      value={{
        userData,
        pavedFee,
        inboxStatus,
        setUserData,
        setPavedFee,
        setInboxStatus,
        breadcrumbLabel,
        setBreadcrumbLabel,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
