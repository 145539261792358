import { Stack } from "@mui/material";
import axios from "axios";
import useComponentDidMount from "hooks/useComponentDidMount";
import { noop } from "lodash";
import HitComponent, {
  Hit as CustomHit,
} from "pages/marketplace/components/Hit";
import { useState } from "react";
import { Hit } from "react-instantsearch-core";
import { useParams } from "react-router-dom";
import NewsLetterInfoList, {
  NewsLetterData,
} from "./ProfileCard/NewsLetterInfoList";

type SingleProfileProps = {
  hit: Hit<CustomHit> | null;
};

function SingleProfile({ hit }: SingleProfileProps) {
  const { mediaPlanId } = useParams();
  const [newsLetterInfoData, setNewsLetterInfoData] = useState<
    NewsLetterData[] | null
  >(null);

  useComponentDidMount(() => {
    if (newsLetterInfoData || !hit) {
      return;
    }
    axios
      .get(
        `/api/v1/advertiser/media_plans/${mediaPlanId}/sponsorship_options?slug=${hit.slug}`
      )
      .then((r) => {
        setNewsLetterInfoData(r.data);
      })
      .catch(noop);
  });

  if (!hit) return null;
  return (
    <Stack className="profile-search-wrapper">
      <Stack sx={{ p: "24px" }} className="publishers">
        <Stack className="list">
          <HitComponent
            hit={hit}
            wrapEntireContent={false}
            href={`/sites/${hit.slug}?mp_id=${mediaPlanId}`}
            target="_blank"
          ></HitComponent>
        </Stack>
        <NewsLetterInfoList
          data={newsLetterInfoData}
          setData={setNewsLetterInfoData}
          expanded
        />
      </Stack>
    </Stack>
  );
}

export default SingleProfile;
