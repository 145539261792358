import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { PriceDisplay } from "../common/PriceDisplay";
import SubscribersQty from "./SubscribersQty";

interface MediaPlanTotalRowProps {
  subscribersCount: number | null;
  totalPrice: number;
}

const MediaPlanTotalRow: React.FC<MediaPlanTotalRowProps> = ({
  subscribersCount,
  totalPrice,
}) => {
  return (
    <Grid container p={2}>
      <Grid item xs={8} py={{ xs: "6px", md: "12px" }}>
        <Stack direction="row" spacing={2} pl="40px" alignItems="center">
          <Typography noWrap fontWeight="bold" fontSize="16px">
            Total:
          </Typography>
          <SubscribersQty subscribers={subscribersCount} />
        </Stack>
      </Grid>
      <Grid item xs={2} py={{ xs: "6px", md: "12px" }} textAlign="center">
        <PriceDisplay price={totalPrice} bold />
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};

export default MediaPlanTotalRow;
