import { Search } from "@mui/icons-material";
import { Grid, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { TextField } from "../../../../../components/MUIWrappers";
import { MediaPlanField, MediaPlanStatus } from "../../types";
import { Icon } from "shared-components";

type SortableItem = {
  name: MediaPlanField;
  caption: string;
};

const sortableItems: Record<MediaPlanStatus, SortableItem[]> = {
  draft: [
    { name: "sponsorshipsCount", caption: "Sponsorships" },
    { name: "price", caption: "Total Budget" },
  ],
  in_progress: [
    { name: "sponsorshipsCount", caption: "Progress" },
    { name: "price", caption: "Budget" },
    { name: "actionsRequired", caption: "Actions Required" },
  ],
  completed: [
    { name: "bookingDate", caption: "Booking Date" },
    { name: "price", caption: "Budget" },
  ],
};

// TODO: move it to shared-components and set colors from the theme
function SortHandler({
  sortDirection,
}: {
  sortDirection?: "asc" | "desc" | undefined;
}) {
  return (
    <Stack
      height="24px"
      sx={{ userSelect: "none" }}
      spacing={-0.5}
      justifyContent="center"
    >
      <Icon
        name="up"
        fill={sortDirection === "asc" ? "#0E63F4" : "lightgray"}
        height={18}
        weight={900}
      />
      <Icon
        name="down"
        height={18}
        weight={900}
        fill={sortDirection === "desc" ? "#0E63F4" : "lightgray"}
      />
    </Stack>
  );
}

function SortableItemNode({
  name,
  caption,
  sortBy,
  sortDirection,
  setSortBy,
  toggleSortDirection,
  xs,
}: {
  name: MediaPlanField;
  caption: string;
  sortBy?: string;
  sortDirection?: "asc" | "desc";
  setSortBy: (value: MediaPlanField) => void;
  toggleSortDirection: () => void;
  xs: number;
}) {
  return (
    <Grid
      item
      xs={xs}
      sx={{
        textAlign: "center",
        borderRight: "2px solid #E0E0E0",
        "&:last-child": { borderRight: "none" },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          cursor: "pointer",
        }}
        onClick={() => {
          setSortBy(name);
          toggleSortDirection();
        }}
      >
        <Typography fontWeight="bold" sx={{ userSelect: "none", mr: "12px" }}>
          {caption}
        </Typography>
        <SortHandler
          sortDirection={sortBy === name ? sortDirection : undefined}
        />
      </Stack>
    </Grid>
  );
}

export default function MediaPlansTableHeader({
  status,
  search,
  setSearch,
  sortBy,
  sortDirection,
  setSortBy,
  toggleSortDirection,
}: {
  status: MediaPlanStatus;
  search: string;
  setSearch: (value: string) => void;
  sortBy?: string;
  sortDirection?: "asc" | "desc" | undefined;
  setSortBy: (value: MediaPlanField) => void;
  toggleSortDirection: () => void;
}) {
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showInput) {
      inputRef.current?.focus();
    }
  }, [showInput]);

  const placeholder = search || "Media Plan Name";

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        px: 4,
        py: 2,
        mb: 3,
        borderBottom: "1px solid #D4D6E4 ",
        borderRadius: "0 0 8px 8px",
        boxShadow: "0px 6px 8px 0px #EEE7F766",
        position: "sticky",
        top: 0,
        left: 0,
        bgcolor: "background.paper",
      }}
    >
      <Grid
        item
        xs={6}
        sx={{ borderRight: "2px solid #E0E0E0", cursor: "pointer" }}
        onClick={() => setShowInput(true)}
        pr={2}
      >
        {showInput ? (
          <TextField
            fullWidth
            size="small"
            variant="standard"
            onBlur={() => setShowInput(false)}
            placeholder={placeholder}
            inputRef={inputRef}
            InputProps={{
              endAdornment: <Search color="disabled" />,
              disableUnderline: true,
              sx: {
                borderBottom: "none",
                "&:before": {
                  borderBottom: "none",
                },
                "&:after": {
                  borderBottom: "none",
                },
                "& .MuiInputBase-input": {
                  p: 0,
                  fontSize: "18px",
                  minWidth: "auto",
                  color: "text.primary",
                },
              },
            }}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        ) : (
          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight="bold">{placeholder}</Typography>
            <Search color="disabled" />
          </Stack>
        )}
      </Grid>
      {sortableItems[status].map((item: SortableItem) => (
        <SortableItemNode
          key={item.name}
          name={item.name}
          caption={item.caption}
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSortBy={setSortBy}
          toggleSortDirection={toggleSortDirection}
          xs={6 / sortableItems[status].length}
        />
      ))}
    </Grid>
  );
}
