import { Add } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container, Stack, Typography } from "@mui/material";
import { useIsMobile } from "../../../../../../hooks/useIsMobile";
import { useState } from "react";
import ReactDOM from "react-dom";
import { Button } from "shared-components";
import PriceInfoData, { PriceInfoDataProps } from "./PriceInfoData";

export type PriceInfoProps = PriceInfoDataProps & {
  onAddSponsorshipClick: () => void;
  onBookClick: () => void;
};

const ShowDetailsButton = ({
  showDetails,
  setShowDetails,
}: {
  showDetails: boolean;
  setShowDetails: (value: boolean) => void;
}) => (
  <Button
    onClick={() => setShowDetails(!showDetails)}
    sx={{
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      top: -24,
      height: 48,
      width: 48,
      minWidth: 48,
      borderRadius: "50%",
      bgcolor: "common.white",
      boxShadow: "0px -4px 16px 1px #EEE7F7CC",
      "&:hover": {
        bgcolor: "common.white",
      },
    }}
  >
    <ExpandMoreIcon
      sx={{
        transition: "transform 0.3s",
        transform: showDetails ? "rotate(0deg)" : "rotate(180deg)",
      }}
    />
  </Button>
);

function PriceInfoContent({
  booked,
  regularPrice,
  totalPrice,
  discountPrice,
  discountPercent,
  expireInDays,
  onAddSponsorshipClick,
  onBookClick,
}: PriceInfoProps) {
  const isMobile = useIsMobile();
  const [showDetails, setShowDetails] = useState(() => !isMobile);

  return (
    <Stack
      bgcolor="white"
      sx={{
        position: "fixed",
        width: "100svw",
        bottom: 0,
        left: 0,
        zIndex: 1,
        p: "12px 0 16px 0",
        "&.MuiPaper-root": { minHeight: "auto" },
        boxShadow: "0px -2px 8px 0px #EEE7F7",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          justifyContent: "space-between",
          gap: { xs: 2, md: 0 },
        }}
      >
        {isMobile && !!totalPrice && totalPrice !== regularPrice && (
          <ShowDetailsButton
            showDetails={showDetails}
            setShowDetails={setShowDetails}
          />
        )}

        <Stack
          direction="column"
          width={{ md: "50%" }}
          justifyContent="space-between"
          spacing={1}
        >
          {!!discountPercent && discountPercent > 0 && (
            <Typography color="error.main" fontSize="12px">
              ⌛ Prices may change and spots could disappear soon.{" "}
              <b>Don’t miss out—book now to lock in your rate!</b>
            </Typography>
          )}
          <Stack spacing={1} direction="row">
            <Button
              variant="outlined"
              fullWidth
              size="large"
              onClick={onAddSponsorshipClick}
              sx={{ whiteSpace: "nowrap" }}
              startIcon={<Add />}
              disabled={booked}
            >
              Add Sponsorship
            </Button>
            <Button
              variant="contained"
              fullWidth
              size="large"
              disabled={!totalPrice || booked}
              onClick={onBookClick}
            >
              {isMobile ? "Book All" : "Book All Sponsorships"}
            </Button>
          </Stack>
        </Stack>
        <Stack width={{ md: "33%" }} pr={{ md: 6.5 }}>
          <PriceInfoData
            discountPrice={discountPrice}
            regularPrice={regularPrice}
            totalPrice={totalPrice}
            discountPercent={discountPercent}
            expireInDays={expireInDays}
            showDetails={showDetails}
          />
        </Stack>
      </Container>
    </Stack>
  );
}

export default function PriceInfo(props: PriceInfoProps) {
  return ReactDOM.createPortal(<PriceInfoContent {...props} />, document.body);
}
