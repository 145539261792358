import { Button } from "shared-components";

function ActionRequiredButton({
  status,
  review_link,
  id,
  type,
}: {
  status: string;
  review_link: null | string;
  id: number;
  type: string;
}) {
  const labelMap: Record<string, string | null> = {
    Canceled: "Canceled",
    Requested: null,
    Creative: "Upload Creative",
    Payment: "Process Payment",
    "Awaiting Draft": null,
    Approval: "Review Draft",
    Scheduled: null,
    Sent: review_link ? "Review for $20" : "View Sponsorship",
  };

  const btnLabel = labelMap[status] || "View Sponsorship";

  return (
    <Button
      variant="outlined"
      disabled={status === "Canceled"}
      color={review_link ? "success" : "inherit"}
      fullWidth
      onClick={() =>
        window.open(
          review_link
            ? review_link
            : status === "Creative"
            ? `/advertiser/campaigns/${id}/creative/edit`
            : `/advertiser/campaigns/${id}`,
          "_blank"
        )
      }
    >
      {btnLabel}
    </Button>
  );
}

export default ActionRequiredButton;
