import React from "react";
import { Chip as MuiChip } from "@mui/material";

const IconChip = ({
  icon,
  label,
  textColor = "#6D7379",
  labelClassName,
  fullWidth,
}: {
  icon: React.ReactElement;
  label: React.ReactNode;
  textColor?: string;
  labelClassName?: string;
  fullWidth?: boolean;
}) => (
  <MuiChip
    icon={icon}
    label={label}
    sx={{
      pl: "10px",
      ".MuiChip-label": { px: "10px" },
      bgcolor: "rgba(248, 250, 255, 1)",
      color: textColor,
      fontWeight: 500,
      borderRadius: "4px",
      fontSize: "14px",
      lineHeight: "24px",
      height: "32px",
      width: fullWidth ? "100%" : "auto",
    }}
    classes={{ label: labelClassName }}
  />
);

export default IconChip;
