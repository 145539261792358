import React from "react";

import { Box, Chip, Collapse, Divider } from "@mui/material";
import { currencyFormatter } from "../../../../../../utils/formatters";

export type PriceInfoDataProps = {
  booked?: boolean;
  regularPrice?: number;
  discountPrice?: number;
  discountPercent?: number;
  totalPrice?: number;
  expireInDays?: number;
  showDetails?: boolean;
};

function PriceInfoRow({
  caption,
  value,
  isDiscount = false,
  discountPercent,
  isTotal,
}: {
  caption: string;
  value?: number;
  isDiscount?: boolean;
  discountPercent?: number;
  isTotal?: boolean;
}) {
  const valueString = value ? currencyFormatter(value, 0) : "- -";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        fontSize: "16px",
        alignItems: "center",
      }}
    >
      <Box>{caption}:</Box>
      <Box
        display="flex"
        justifyContent="space-between"
        gap="8px"
        alignItems="center"
        fontWeight={isTotal ? 700 : 400}
      >
        {!!discountPercent && (
          <Chip
            label={`${discountPercent}%`}
            color="success"
            sx={{
              color: "white",
              fontWeight: 700,
              fontSize: "16px",
              height: "24px",
            }}
          />
        )}
        {isDiscount && value ? `-${valueString}` : valueString}
      </Box>
    </Box>
  );
}

function ExpireInDaysMessage({ expireInDays }: { expireInDays?: number }) {
  return expireInDays ? (
    <>
      <Box
        sx={{
          color: "error.dark",
          fontSize: "12px",
          fontWeight: 700,
          mb: "8px",
        }}
      >
        ⌛&nbsp;Limited Time Offer
      </Box>
      <Box
        sx={{
          color: "error.dark",
          fontSize: "12px",
          mb: "20px",
        }}
      >
        This discount will expire in {expireInDays} days. <br /> Book all now!
      </Box>
    </>
  ) : null;
}

export default function PriceInfoData({
  regularPrice,
  totalPrice,
  discountPrice,
  discountPercent,
  expireInDays,
  showDetails = true,
}: PriceInfoDataProps) {
  return (
    <div>
      <Collapse in={showDetails} timeout="auto" unmountOnExit>
        <PriceInfoRow caption="Regular Price" value={regularPrice} />
        {!!discountPrice && discountPrice > 0 && (
          <PriceInfoRow
            caption="Discount"
            value={discountPrice}
            isDiscount={true}
            discountPercent={discountPercent}
          />
        )}
      </Collapse>
      <PriceInfoRow caption="Total Price" isTotal value={totalPrice} />
      {expireInDays && <Divider sx={{ mb: "20px" }} />}
      <ExpireInDaysMessage expireInDays={expireInDays} />
    </div>
  );
}
