// theme.spacing(1) === 8
export const p = {
  xs: 1,
  sm: 2,
  md: 3,
};

// theme.shape.borderRadius === 4
export const borderRadius = {
  xs: 2,
  sm: 3,
  md: 4,
};
