import { Add } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";

function EmptyState({
  onAddSponsorshipClick,
}: {
  onAddSponsorshipClick?: () => void;
}) {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      maxWidth="100svw"
      my="auto"
    >
      <Typography variant="caption" color="text.secondary" textAlign="center">
        Your media plan is empty.
        <br />
        Start building your media plan by adding a sponsorship.
      </Typography>
      <Button startIcon={<Add />} onClick={onAddSponsorshipClick}>
        Add Sponsorship
      </Button>
    </Stack>
  );
}

export default EmptyState;
