import React from "react";
import { Chip } from "@mui/material";
import { amber, blue, green, red, grey } from "@mui/material/colors";

interface StatusChipProps {
  status: string;
}

interface ColorShades {
  50: string;
  500: string;
}

const colorMap: Record<string, ColorShades> = {
  Canceled: red,
  Requested: red,
  Creative: blue,
  Payment: blue,
  "Awaiting Draft": blue,
  Approval: blue,
  Scheduled: amber,
  Sent: green,
};

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
  const chipColor: ColorShades = colorMap[status] || grey;

  return (
    <Chip
      label={status}
      size="small"
      sx={{
        minWidth: "100px",
        height: "20px",
        fontSize: "12px",
        bgcolor: chipColor[50],
        color: chipColor[500],
      }}
    />
  );
};

export default StatusChip;
