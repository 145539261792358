import React from "react";
import { ConfirmationMessageDialog } from "./ConfirmationMessageDialog";

export type WarningMessageDialogProps = {
  messageTitle: string;
  messageDescription: string;
  onClose: () => void;
  onSubmit: () => void;
  caption?: string;
};

export function WarningMessageDialog({
  messageTitle,
  messageDescription,
  onClose,
  onSubmit,
  caption = "OK",
}: WarningMessageDialogProps) {
  return (
    <ConfirmationMessageDialog
      open={true}
      messageDescription={messageDescription}
      messageTitle={messageTitle}
      variant="warning"
      onClose={onClose}
      actions={[
        {
          caption: "Cancel",
          onClick: onClose,
          type: "button",
        },
        {
          caption,
          onClick: onSubmit,
          type: "submit",
          severity: "error",
        },
      ]}
    />
  );
}
