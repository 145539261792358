import { Box, Alert, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

interface NotificationMessagesProps {
  datesChanged?: number;
  pricesChanged: number;
}

type NotificationAlertProps = {
  severity: "warning" | "info";
  title: string;
  message: string;
  color: string;
  backgroundColor?: string;
};

const NotificationAlert = ({
  severity,
  title,
  message,
  color,
  backgroundColor,
}: NotificationAlertProps) => (
  <Alert
    severity={severity}
    icon={<InfoOutlined fontSize="inherit" />}
    sx={{
      ...(backgroundColor && { bgcolor: backgroundColor }),
      mb: 1,
      ".MuiAlert-icon": {
        py: "9px",
      },
    }}
  >
    <Box display="flex" gap={1} alignItems="baseline" flexWrap="wrap">
      <Typography fontWeight={600} sx={{ color }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ color }}>
        {message}
      </Typography>
    </Box>
  </Alert>
);

export default function Notifications({
  datesChanged = 0,
  pricesChanged,
}: NotificationMessagesProps) {
  // // dates are not linked to the context to prevent early hiding when the context changes
  // const [datesChanged] = useState(datesChanged);

  // const [pricesChanged, setPricesChanged] = useState(pricesChanged);

  // // keep prices reactive, allows to hide the price notification when the context changes
  // useEffect(() => {
  //   setPricesChanged(
  //     changes.find((item) => item.pricesChanged !== undefined)?.pricesChanged ??
  //       0
  //   );
  // }, [changes]);

  if (datesChanged === 0 && pricesChanged === 0) return null;

  return (
    <>
      <Box sx={{ mb: 2 }}>
        {pricesChanged > 0 && (
          <NotificationAlert
            severity="warning"
            title="Price increase detected!"
            message={`Review the updated ${
              pricesChanged > 1 ? "prices" : "price"
            } or proceed with booking.`}
            color="#9A5C00"
          />
        )}

        {datesChanged > 0 && (
          <NotificationAlert
            severity="info"
            title={`Placement ${datesChanged > 1 ? "dates" : "date"} updated!`}
            message={`${datesChanged} ${
              datesChanged > 1 ? "dates were" : "date was"
            } automatically adjusted to the nearest available options.`}
            color="#0E63F4"
            backgroundColor="#E9F0FE"
          />
        )}
      </Box>
    </>
  );
}
