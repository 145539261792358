import axios from "axios";
import { noop } from "lodash";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { MediaPlanContext } from "../../../context";
import { Profile } from "../../../types";
import { prepareMediaPlan } from "../../../utils";
import MediaPlanLayout from "..";
import ProfileRow from "./ProfileRow";
import RemoveProfileModal from "./RemoveProfileModal";

const headerConfig = [
  { xs: 4, label: "Newsletter and Placement", textAlign: "left", pl: "12px" },
  { xs: 1.5, label: "Quantity", textAlign: "center" },
  { xs: 1.5, label: "Avg. Est. Clicks", textAlign: "center" },
  { xs: 1.5, label: "Avg. CPC", textAlign: "center" },
  { xs: 2, label: "Price", textAlign: "center" },
  { xs: 1.5, label: "" },
];

function DratMediaPlan({
  onAddSponsorshipClick,
}: {
  onAddSponsorshipClick?: () => void;
}) {
  const { setMediaPlan, profiles } = useContext(MediaPlanContext)!;
  const { mediaPlanId } = useParams();
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);
  const [modalType, setModalType] = useState<"confirm" | "success" | null>(
    null
  );

  const openRemoveModal = (profile: Profile) => {
    setSelectedProfile(profile);
    setModalType("confirm");
  };

  const handleRemove = async (profile: Profile) => {
    try {
      const response = await axios.delete(
        `/api/v1/advertiser/media_plans/${mediaPlanId}/`,
        { params: { site_id: profile.id } }
      );
      setMediaPlan(prepareMediaPlan(response.data));
      setModalType("success");
    } catch (error) {
      noop();
    }
  };

  const totalPrice = profiles.reduce(
    (sum, profile) =>
      sum +
      profile.sponsorshipOptions.reduce(
        (profileSum, option) => profileSum + option.price,
        0
      ),
    0
  );
  const totalSubscribers = profiles.reduce(
    (sum, profile) => sum + (profile.subscribers || 0),
    0
  );

  return (
    <>
      <MediaPlanLayout
        headerConfig={headerConfig}
        profiles={profiles}
        totalPrice={totalPrice}
        renderRow={(profile: Profile) => (
          <ProfileRow
            key={profile.name}
            profile={profile}
            onRemove={openRemoveModal}
          />
        )}
        subscribersCount={totalSubscribers}
        onAddSponsorshipClick={onAddSponsorshipClick}
      />

      {/* Remove Profile Modal */}
      {selectedProfile && (
        <RemoveProfileModal
          profile={selectedProfile}
          modalType={modalType}
          setModalType={setModalType}
          handleRemove={handleRemove}
        />
      )}
    </>
  );
}

export default DratMediaPlan;
