import React from "react";
import { Dialog as MUIDialog, DialogProps } from "@mui/material";

import { borderRadius } from "./variables";

export function Dialog(props: DialogProps) {
  return (
    <MUIDialog
      {...props}
      sx={{
        borderRadius,
        ...props.sx,
      }}
      PaperProps={{
        sx: {
          borderRadius: props.fullScreen ? 0 : borderRadius,
          overflow: "hidden",
        },
      }}
    />
  );
}
