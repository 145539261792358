import { Stack, Typography, Divider, Grid } from "@mui/material";
import axios from "axios";
import { differenceInDays } from "date-fns";
import { noop } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { CommonDrawer } from "shared-components";
import { MediaPlanContext } from "../../context";
import { Availabilities, DateOption, SponsorshipOption } from "../../types";
import { prepareMediaPlan } from "../../utils";
import AvailabilitiesCalendar from "./Calendar";
import { computePricesByDate, formatDateKey } from "./helpers";
import { currencyFormatter } from "utils/formatters";

type DateSelectorProps = {
  profileSlug: string;
  open: boolean;
  sponsorshipOption: SponsorshipOption;
  closeDatePicker: () => void;
  expandRow: () => void;
};

function DateSelector({
  open,
  sponsorshipOption,
  closeDatePicker,
  expandRow,
}: DateSelectorProps) {
  const { setMediaPlan } = useContext(MediaPlanContext)!;
  const { mediaPlanId } = useParams();
  const [availabilities, setAvailabilities] = useState<Availabilities | null>(
    null
  );
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [outdatedDates, setOutdatedDates] = useState<Date[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const hasInvalidDates = useMemo(() => {
    if (!selectedDates.length || !availabilities) return false;
    const minDate = new Date(availabilities.min_date);
    return selectedDates.some((date) => {
      const dateKey = formatDateKey(date);
      const isPastDate = differenceInDays(date, minDate) < 0;
      const isUnavailable = availabilities.unavailable_dates.includes(dateKey);
      return isPastDate || isUnavailable;
    });
  }, [selectedDates, availabilities]);

  useEffect(() => {
    if (!open) return;
    if (sponsorshipOption?.dates?.length) {
      const minDate = new Date(availabilities?.min_date ?? 0);
      const validDates: Date[] = [];
      const outdated: Date[] = [];
      sponsorshipOption.dates.forEach((item: DateOption) => {
        const date = new Date(item.date);
        if (differenceInDays(date, minDate) < 0) {
          outdated.push(date);
        } else {
          validDates.push(date);
        }
      });
      setOutdatedDates(outdated);
      setSelectedDates(validDates);
    }
  }, [sponsorshipOption, open, availabilities]);

  const pricesByDate = useMemo(
    () =>
      availabilities && computePricesByDate(availabilities, sponsorshipOption),
    [availabilities, sponsorshipOption]
  );

  useEffect(() => {
    const getAvailabilities = async () => {
      try {
        const response = await axios.get(
          `/api/v1/advertiser/media_plans/${mediaPlanId}/sponsorship_option_availabilities?so_id=${sponsorshipOption?.id}`
        );
        const { unavailable_dates, min_date, max_date, prices, normal_price } =
          response.data;
        setAvailabilities({
          unavailable_dates: unavailable_dates.map(
            (d: string) => d.split("T")[0]
          ),
          min_date,
          max_date,
          prices,
          normal_price,
        });
      } catch (error) {
        noop();
      }
    };

    if (sponsorshipOption && open) {
      getAvailabilities();
    }
  }, [mediaPlanId, open, sponsorshipOption]);

  const handleDatesSubmit = async () => {
    setIsUpdating(true);
    const datesWithPrices = selectedDates.map((date) => {
      const dateKey = formatDateKey(date);
      const fallbackPrice = availabilities?.normal_price;
      const datePrice = pricesByDate?.[dateKey]?.price ?? fallbackPrice;
      return { date: `${dateKey}T00:00:00Z`, price: datePrice };
    });

    const queryString = datesWithPrices
      .map(
        (d) =>
          `dates_with_prices[][date]=${d.date}&dates_with_prices[][price]=${d.price}`
      )
      .join("&");

    try {
      const { data } = await axios.patch(
        `/api/v1/advertiser/media_plans/${mediaPlanId}/media_plan_sponsorship_options/update_dates?so_id=${sponsorshipOption?.id}&${queryString}`
      );
      setMediaPlan(prepareMediaPlan(data));
      setOutdatedDates([]);
      closeDatePicker();
      expandRow();
    } catch (error) {
      noop();
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <CommonDrawer
      open={open}
      title={sponsorshipOption.name}
      actions={[
        { caption: "Back", onClick: closeDatePicker, type: "button" },
        {
          caption: isUpdating ? "Updating..." : "Update Dates",
          onClick: handleDatesSubmit,
          disabled: !selectedDates.length || hasInvalidDates || isUpdating,
          type: "submit",
        },
      ]}
      dividers
      onClose={closeDatePicker}
    >
      <Stack px={{ xs: 1, md: 3 }} py={4} gap={3}>
        {availabilities && (
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography fontWeight="bold"> Placement: </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography> {sponsorshipOption.name} </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography fontWeight="bold"> Regular Price: </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                {currencyFormatter(availabilities?.normal_price, 0)}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Divider />
        <Typography fontSize="14px">
          What dates would you like this sponsorship to run? Select the dates
          and add them to your media plan:
        </Typography>
        {availabilities && (
          <AvailabilitiesCalendar
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            outdatedDates={outdatedDates}
            minDate={new Date(availabilities.min_date)}
            maxDate={new Date(availabilities.max_date)}
            value={selectedDates}
            unavailableDates={availabilities.unavailable_dates}
            pricesByDate={pricesByDate}
          />
        )}
      </Stack>
    </CommonDrawer>
  );
}

export default DateSelector;
