import { Grid } from "@mui/material";

const headerRowStyle = {
  borderBottom: "1px solid #D4D6E4 #DFE3EB",
  borderRadius: "8px",
  fontWeight: "bold",
  p: "8px 20px",
  boxShadow: "0px 6px 8px 0px #EEE7F766",
  position: "sticky",
  top: 0,
  left: 0,
  bgcolor: "background.paper",
  zIndex: 1,
};

function TableHeaderWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Grid container sx={headerRowStyle}>
      {children}
    </Grid>
  );
}

export default TableHeaderWrapper;
