import React from "react";
import { Button as MUIButton, ButtonProps } from "@mui/material";

export function Button(props: ButtonProps) {
  const size = props.size || "medium";

  return (
    <MUIButton
      size={size}
      {...props}
      sx={{
        fontSize: "14px",
        fontWeight: 500,
        ...(size === "small" && {
          height: "32px",
          p: "0 16px",
          borderRadius: "4px",
        }),
        ...(size === "medium" && {
          height: "40px",
          p: "0 20px",
          borderRadius: "8px",
        }),
        ...(size === "large" && {
          height: "48px",
          p: "0 24px",
          borderRadius: "8px",
        }),
        ...props.sx,
      }}
    />
  );
}
