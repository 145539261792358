import { useContext, useEffect, useMemo, useRef, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { Configure, InstantSearch } from "react-instantsearch";
import "./styles.scss";
import Categories from "./components/Categories";
import Content from "./components/Content";
import { UserContext } from "context";
import Filters from "./components/Filters";
import Header from "./components/Header";
import type { IndexUiState, UiState } from "instantsearch.js";
import { getFilter, MIN, MAX } from "./components/SponsorshipCostSlider";
import { useLocation, useMatch, useParams } from "react-router-dom";
import axios from "axios";
import { INDEX_NAME, RECENT_INDEX_NAME } from "./components/SortBy";
import { noop } from "lodash";
import { hasActiveUserFilters } from "./components/ClearRefinements";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIASEARCH_APP_ID ?? "",
  process.env.REACT_APP_ALGOLIASEARCH_READ_KEY_PUBLIC ?? ""
);

const RECENT_DAYS_AGO = 60;
const recentEpoch = Math.floor(
  (new Date().getTime() - RECENT_DAYS_AGO * 24 * 60 * 60 * 1000) / 1000
);

const mergeFilters = (filters: string, facetFilters: string = "") => {
  return [filters && `(${filters})`, facetFilters]
    .filter(Boolean)
    .join(" AND ");
};

export default function MarketplaceContainer() {
  const { siteSlug } = useParams();

  const searchParams = new URLSearchParams(window.location.search);
  const domain = searchParams.get("domain");
  const query = searchParams.get("query");

  const [data, setPageData] = useState<{
    name: string;
    title: string;
    description: string;
  } | null>(null);
  const {
    userData: { uuid, ro_id, companyId, companyName },
  } = useContext(UserContext);

  const location = useLocation();

  const isCollectionPage = !!useMatch("/collections/:siteSlug");
  const isCategoryPage = !!useMatch("/categories/:siteSlug");
  const isFavoritesPage = !!useMatch("/categories/favorites");

  const isRecentPage = !!useMatch("/categories/recent");
  const isRecommendationPage = !!useMatch("/categories/recommendations");
  const isMarketplacePage = !!useMatch({ path: "/marketplace", end: true });
  const isSearchByAdvertiser = !!useMatch("/search/advertiser_search");
  const isSearchPage = !!useMatch({ path: "/search", end: true });
  const isRebookingsPage = !!useMatch("/marketplace/rebookings");

  useEffect(() => {
    document.body.classList.add("marketplace-body");
    return () => {
      document.body.classList.remove("marketplace-body");
    };
  }, []);

  useEffect(() => {
    setPageData(null);
  }, [location.pathname]);

  useEffect(() => {
    if (!companyId) return;
    if (isSearchByAdvertiser) {
      if (!domain) {
        setPageData({
          name: "",
          title: "Search By Advertiser",
          description: "",
        });
        return;
      }
      axios
        .post("/api/v1/search_by_advertiser", {
          domain,
        })
        .then((response) => {
          const advertiserName = response.data.advertiser_name;

          setPageData({
            name: "",
            title: "Search By Advertiser",
            description: advertiserName
              ? `These are sites who have listed ${advertiserName} as a previous sponsor`
              : "",
          });
        })
        .catch(noop);
    } else if (isRebookingsPage) {
      axios
        .get(`/api/v1/categories/rebookings`)
        .then((response) => {
          setPageData({
            name: "",
            title: companyName
              ? `${companyName}'s Top Performers`
              : "Top Performers",
            description: response.data.description,
          });
        })
        .catch(noop);
    } else if (isCollectionPage) {
      axios
        .get(`/api/v1/collections/${siteSlug}`, {
          processErrorInComponentStatuses: [404],
        })
        .then((response) => {
          setPageData({
            name: response.data.name,
            title: response.data.name,
            description: response.data.description,
          });
        })
        .catch(noop);
    } else if (isCategoryPage) {
      axios
        .get(`/api/v1/categories/${siteSlug}`, {
          processErrorInComponentStatuses: [404],
        })
        .then((response) => {
          const title =
            isRecentPage || isRecommendationPage || isFavoritesPage
              ? response.data.heading
              : `${response.data.heading} Publishers`;

          setPageData({
            name: response.data.name,
            title,
            description: response.data.description,
          });
        })
        .catch(noop);
    }
  }, [
    isCollectionPage,
    isCategoryPage,
    isFavoritesPage,
    siteSlug,
    isRecentPage,
    isRecommendationPage,
    isSearchByAdvertiser,
    domain,
    companyName,
    isRebookingsPage,
    isSearchPage,
    query,
    companyId,
  ]);

  const facetFilters = useMemo(() => {
    if (!ro_id && !companyId) return undefined;
    if (!data) return undefined;

    if (isSearchByAdvertiser && domain)
      return `previous_advertiser_domains:'${domain}'`;
    if (isRecentPage) return `activated_at_epoch>${recentEpoch}`;
    if (isFavoritesPage) return `favorites:${ro_id}`;
    if (isRecommendationPage) return `recommendations:${companyId}`;
    if (isCollectionPage) return `collections_list:${siteSlug}`;
    if (isCategoryPage) return `category_names:'${data.name}'`;
    if (isRebookingsPage) return `rebookings: ${ro_id}`;
    return undefined;
  }, [
    ro_id,
    companyId,
    data,
    isSearchByAdvertiser,
    domain,
    isRecentPage,
    isFavoritesPage,
    isRecommendationPage,
    isCollectionPage,
    siteSlug,
    isCategoryPage,
    isRebookingsPage,
  ]);

  if ((isMarketplacePage || isSearchPage) && uuid && ro_id) {
    return <Marketplace title="" description="" />;
  }

  return (
    <>
      {uuid && ro_id && data && (
        <Marketplace
          title={data?.title || ""}
          description={data?.description || ""}
          facetFilters={facetFilters}
        />
      )}
    </>
  );
}

function Marketplace({
  title,
  description,
  facetFilters,
}: {
  title: string;
  description: string;
  facetFilters?: string;
}) {
  const {
    userData: { uuid },
  } = useContext(UserContext);
  const location = useLocation();

  const isRecentPage = !!useMatch("/categories/recent");
  const isMarketplacePage = !!useMatch({ path: "/marketplace", end: true });

  const searchParams = new URLSearchParams(location.search);
  const domain = searchParams.get("domain");

  const [priceValue, setPriceValue] = useState<number[]>([MIN, MAX]);
  const containerRef = useRef<HTMLDivElement>(null);

  const handlePageClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as Element).matches(".ais-Pagination-link")) {
      containerRef.current!.scrollIntoView({ behavior: "smooth" });
    }
  };

  const ref = useRef<number[]>();
  ref.current = priceValue;

  const onSearchStateChange = ({
    setUiState,
    uiState,
  }: {
    setUiState: (state: UiState) => void;
    uiState: UiState;
  }) => {
    const { refinementList, configure } = uiState[INDEX_NAME];

    const currentFormats = refinementList?.["formats"] || [];

    const priceFilters = getFilter(
      priceValue[0],
      priceValue[1],
      currentFormats
    );

    const filters = mergeFilters(priceFilters, facetFilters);

    const newState = {
      [INDEX_NAME]: {
        ...uiState[INDEX_NAME],
        configure: {
          ...configure,
          filters,
        },
      },
    };
    setUiState(newState);
  };

  const hasActiveFilters = (state: IndexUiState) =>
    hasActiveUserFilters(state, priceValue, [MIN, MAX]);

  return (
    <>
      {isMarketplacePage && <Categories />}
      <InstantSearch
        searchClient={searchClient}
        indexName={INDEX_NAME}
        insights={true}
        key={location.pathname}
        routing={{
          stateMapping: {
            stateToRoute: (uiState) => {
              const indexUiState = uiState[INDEX_NAME];
              return {
                domain: domain ? domain : undefined,
                query: indexUiState.query,
                page: indexUiState.page,
                toggle: indexUiState.toggle,
                refinementList: indexUiState.refinementList,
                range: indexUiState.range,
                price: ref.current,
                sortBy: indexUiState.sortBy?.replace(`${INDEX_NAME}_`, ""),
              };
            },
            routeToState: (routeState) => {
              const currentFormats =
                routeState.refinementList?.["formats"] || [];
              const priceRange = routeState.price?.map(Number) || [MIN, MAX];
              setPriceValue(priceRange);

              const priceFilters = getFilter(
                priceRange[0],
                priceRange[1],
                currentFormats
              );

              const filters = mergeFilters(priceFilters, facetFilters);
              return {
                [INDEX_NAME]: {
                  query: routeState.query,
                  refinementList: routeState.refinementList,
                  toggle: routeState.toggle,
                  page: routeState.page,
                  range: routeState.range,
                  sortBy: routeState.sortBy
                    ? `${INDEX_NAME}_${routeState.sortBy}`
                    : isRecentPage
                    ? RECENT_INDEX_NAME
                    : undefined,
                  configure: {
                    filters,
                  },
                  domain: routeState.domain,
                },
              };
            },
          },
        }}
        onStateChange={onSearchStateChange}
      >
        <Header title={title} description={description} />

        <section className="catalog" ref={containerRef}>
          <Filters
            priceValue={priceValue}
            setPriceValue={setPriceValue}
            hideCategory={!!facetFilters?.includes("category_names")}
          />
          <div className="content">
            <Configure
              hitsPerPage={12}
              distinct
              clickAnalytics
              enablePersonalization={
                process.env.REACT_APP_APP_ENV === "production"
              }
              userToken={uuid}
              facets={["favorites"]}
            />
            <Content
              handlePageClick={handlePageClick}
              hasActiveFilters={hasActiveFilters}
            />
          </div>
        </section>
      </InstantSearch>
    </>
  );
}
