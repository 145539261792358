export enum MediaPlanStatus {
  DRAFT = "draft",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
}

export enum SponsorshipStatus {
  PENDING = "Pending",
  APPROVED = "Approved",
  REJECTED = "Rejected",
  COMPLETED = "Completed",
  CANCELED = "Canceled",
}

export enum SponsorshipOptionAction {
  VIEW = "view",
  REVIEW = "review",
  CANCELED = "canceled",
}

export interface DateOption {
  date: string;
  price: number;
  oldPrice: number | null;
  oldDate: string | null;
}

export interface Availabilities {
  normal_price: number;
  min_date: string;
  max_date: string;
  unavailable_dates: string[];
  prices: DateOption[];
}

export interface Sponsorship {
  id: number;
  uuid: string;
  status: string;
  price: number;
  action_required: boolean;
  review_link: string | null;
  run_date: number | null;
  deadline: number | null;
  type: string;
  suggested_or_agreed_date: string | null;
}

export interface SponsorshipOption {
  id: number;
  uuid: string;
  description: string | null;
  name: string;
  price: number;
  quantity: number;
  regularPrice: number;
  cpc: number;
  clickRate: number;
  dates: DateOption[];
  sponsorships: Sponsorship[];
  regularPricePerUnit: number;
}

export interface Profile {
  id: number;
  slug: string;
  name: string;
  logo: string;
  sponsorshipOptions: SponsorshipOption[];
  subscribers: number;
}

export interface MediaPlanData {
  discountPercent: number;
  discountPrice: number;
  name: string;
  profiles: Profile[];
  regularPrice: number;
  first_sponsorship_date: string;
  last_sponsorship_date: string;
  totalPrice: number;
  isBooked: boolean;
  status: MediaPlanStatus;
  datesChanged: number;
  pricesChanged: number;
}

export interface MediaPlanResponseData {
  booked: boolean;
  name: string;
  regular_price: number;
  discount_percent: number;
  discount_price: number;
  total_price: number;
  first_sponsorship_date: string;
  last_sponsorship_date: string;
  status: MediaPlanStatus;
  profiles: {
    id: number;
    slug: string;
    name: string;
    logo: string;
    subscribers: number;
    sponsorship_options: {
      id: number;
      uuid: string;
      name: string;
      description: string | null;
      quantity: number;
      price: number;
      regular_price: number; //TODO: change with regular_price_per_unit
      regular_price_per_unit: number;
      cpc: number;
      click_rate: number;
      dates: {
        date: string;
        price: number;
        old_price: number | null;
        old_date: string | null;
      }[];
      sponsorships: {
        id: number;
        uuid: string;
        status: string;
        price: number;
        action_required: boolean;
        review_link: string | null;
        run_date: number | null;
        deadline: number | null;
        type: string;
        suggested_or_agreed_date: string | null;
      }[];
    }[];
  }[];
  dates_changed: number;
  prices_changed: number;
}

export interface NewInvite {
  firstName: string;
  lastName: string;
  email: string;
}
