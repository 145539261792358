import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import cn from "classnames";
import { Paper } from "components/MUIWrappers";
import { differenceInDays } from "date-fns";
import Calendar, { CalendarProps } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Icon, theme } from "shared-components";
import { formatNumberAsCurrency } from "../../utils";
import "./Calendar.scss";
import { formatDateKey } from "./helpers";

const MAX_SELECTABLE_DATES = 5;

const PriceDisplay = ({
  price,
  isSmart,
}: {
  price: number;
  isSmart: boolean;
}) => (
  <div className={cn("price-by-date", { "price-by-date__smart": isSmart })}>
    <span className="price-label price-label--regular">
      {formatNumberAsCurrency(price, 0)}
    </span>
    <span className="price-label price-label--hidden-mobile">
      {formatNumberAsCurrency(
        price,
        price < 1000 || price > 10000 ? 0 : 1,
        0,
        "USD",
        "compact"
      )}
    </span>
  </div>
);

interface AvailabilitiesCalendarProps {
  selectedDates: Date[];
  outdatedDates: Date[];
  setSelectedDates: React.Dispatch<React.SetStateAction<Date[]>>;
  minDate: Date;
  maxDate: Date;
  value: Date[];
  unavailableDates: string[];
  pricesByDate: Record<string, { price: number; isSmart: boolean }> | null;
}

export default function AvailabilitiesCalendar({
  selectedDates,
  outdatedDates,
  setSelectedDates,
  minDate,
  maxDate,
  value,
  unavailableDates,
  pricesByDate,
}: AvailabilitiesCalendarProps) {
  const getTileContent = ({ date }: { date: Date }) => {
    if (!pricesByDate || differenceInDays(date, minDate) < 0) return null;
    const dateKey = formatDateKey(date);
    return pricesByDate[dateKey] ? (
      <PriceDisplay
        price={pricesByDate[dateKey].price}
        isSmart={pricesByDate[dateKey].isSmart}
      />
    ) : (
      <>&nbsp;</>
    );
  };

  const getTileClassName = ({ date }: { date: Date; view: string }) => {
    const dateString = formatDateKey(date);
    const isUnavailable = unavailableDates.includes(dateString);
    const isSelected = value.some(
      (selectedDate) => formatDateKey(selectedDate) === dateString
    );
    const isOutdated = outdatedDates.some(
      (outdatedDate) => formatDateKey(outdatedDate) === dateString
    );
    return cn("react-calendar__date-item", {
      not_available: isUnavailable,
      selected: isSelected,
      outdated: isOutdated,
    });
  };

  const formatMonth = (locale: string | undefined, date: Date) =>
    new Intl.DateTimeFormat(locale, { month: "long" }).format(date);

  const handleDateChange = (date: Date | Date[]) => {
    const dates = Array.isArray(date) ? date : [date];
    setSelectedDates((prev) => {
      const updated = [...prev];
      dates.forEach((newDate) => {
        const newDateString = newDate.toDateString();
        const index = updated.findIndex(
          (d) => d.toDateString() === newDateString
        );
        if (index !== -1) {
          updated.splice(index, 1);
        } else if (updated.length < MAX_SELECTABLE_DATES) {
          updated.push(newDate);
        }
      });
      return updated.sort((a, b) => a.getTime() - b.getTime());
    });
  };

  const tileDisabled = ({ date }: { date: Date }) => {
    const dateString = formatDateKey(date);
    const isSelected = value.some(
      (selectedDate) => formatDateKey(selectedDate) === dateString
    );
    if (isSelected) return false;
    return (
      differenceInDays(date, minDate) < 0 ||
      unavailableDates.includes(dateString) ||
      value.length >= MAX_SELECTABLE_DATES
    );
  };

  return (
    <Paper sx={{ p: { xs: 1, md: 3 } }}>
      <Calendar
        formatMonthYear={formatMonth}
        onChange={handleDateChange as CalendarProps["onChange"]}
        selectRange={false}
        maxDate={maxDate}
        minDate={minDate}
        value={value as CalendarProps["value"]}
        tileDisabled={tileDisabled}
        tileClassName={getTileClassName}
        tileContent={getTileContent}
        showNeighboringMonth={false}
        locale="en-US"
        minDetail="month"
        prevLabel={
          <IconButton component="div" sx={{ padding: 0 }}>
            <KeyboardDoubleArrowLeft />
          </IconButton>
        }
        nextLabel={
          <IconButton component="div" sx={{ padding: 0 }}>
            <KeyboardDoubleArrowRight />
          </IconButton>
        }
      />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ sm: "center" }}
        justifyContent={{ sm: "space-between" }}
        spacing={2}
        pt={2}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            sx={{
              width: 8,
              height: 8,
              bgcolor: "success.main",
              borderRadius: "50%",
            }}
          />
          <Typography variant="body2">Special Offer</Typography>
        </Stack>
        <Button
          disabled={!selectedDates.length}
          onClick={() => setSelectedDates([])}
          startIcon={
            <Icon
              name="inkEraser"
              fill={
                selectedDates.length
                  ? theme.palette.primary.main
                  : theme.palette.text.disabled
              }
            />
          }
        >
          Clear Selected Dates
        </Button>
      </Stack>
    </Paper>
  );
}
