import { KeyboardArrowLeft } from "@mui/icons-material";
import { Box, Grid, Stack } from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import { noop } from "lodash";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  HelpDrawer,
  Icon,
  SuccessMessageDialog,
  WarningMessageDialog,
} from "shared-components";
import { MediaPlanContext } from "../../../context";
import { NewInvite } from "../../../types";
import DuplicateMediaPlan from "./DuplicateMediaPlan";
import MediaPlanMenu from "./MediaPlanMenu";
import MediaPlanNameTextfield from "./MediaPlanNameTextfield";
import MediaPlanShareModal from "./MediaPlanShare";

const faqs = [
  {
    id: 1,
    question: "How a media plan works?",
    answer:
      "A media plan simplifies the sponsorship booking process by allowing you to select multiple sponsorships, set your preferred dates, and book everything at once—saving time and streamlining your campaign planning process.",
  },
  {
    id: 2,
    question: "How do I track the performance of my media plan?",
    answer:
      "Once your media plan is booked, you can keep track of the sponsorships through the campaign dashboard here. You can easily track key metrics like open rate, clicks, and conversions to measure effectiveness and optimize future plans.",
  },
  {
    id: 3,
    question: "Can I share my media plan with my team?",
    answer:
      "Yes! Media plans can be shared with your team for collaboration and approval before booking. This ensures everyone is aligned on sponsorship selections and campaign strategy.",
  },
  {
    id: 4,
    question: "Can I edit my media plan after booking?",
    answer:
      "Once a media plan is booked, changes may be limited based on factors like publisher availability, expired offers, and booking restrictions. If adjustments are needed, you may need to create a new media plan or contacting support for assistance.",
  },
];

export default function MediaPlanInfo() {
  const { isBooked, name, first_sponsorship_date, last_sponsorship_date } =
    useContext(MediaPlanContext)!;
  const { mediaPlanId } = useParams();
  const navigate = useNavigate();

  const [inviteOpen, setInviteOpen] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteSuccessDialogOpen, setIsDeleteSuccessDialogOpen] =
    useState(false);

  const goBackToDashboard = () => navigate("/advertiser/media_plans");

  const handleInviteClose = () => setInviteOpen(false);

  async function handleAskQuestion(message: string, mediaPlanId?: string) {
    try {
      await axios.post(
        `/api/v1/advertiser/media_plans/${mediaPlanId}/ask_question`,
        { message }
      );
    } catch (error) {
      noop();
    }
  }

  const handleSendInvitations = async (invites: NewInvite[]) => {
    const requestBody = {
      first_name: invites.map(({ firstName }) => firstName),
      last_name: invites.map(({ lastName }) => lastName),
      email: invites.map(({ email }) => email),
    };

    try {
      await axios.post(
        `/api/v1/advertiser/media_plans/${mediaPlanId}/invite_collaborators`,
        requestBody
      );
    } catch (error) {
      noop();
    } finally {
      handleInviteClose();
    }
  };

  const triggerDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteMediaPlan = async () => {
    try {
      await axios.delete(
        `/api/v1/advertiser/media_plans/${mediaPlanId}/destroy_media_plan`
      );
      setIsDeleteDialogOpen(false);
      setIsDeleteSuccessDialogOpen(true);
    } catch (error) {
      noop();
    }
  };

  const handleDeleteSuccessDialogClose = () => {
    setIsDeleteSuccessDialogOpen(false);
    goBackToDashboard();
  };

  const getButtonLabel = () =>
    first_sponsorship_date && last_sponsorship_date
      ? `${format(new Date(first_sponsorship_date), "MMM do, yyyy")} - ${format(
          new Date(last_sponsorship_date),
          "MMM do, yyyy"
        )}`
      : null;

  const renderHeader = (
    <Box sx={{ p: "16px", pb: { xs: "0px", md: "16px" } }}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={7}>
          <Stack direction="row" spacing={2}>
            <Button
              onClick={goBackToDashboard}
              color="inherit"
              variant="outlined"
              sx={{ minWidth: 40, width: 40, p: 0 }}
            >
              <KeyboardArrowLeft />
            </Button>
            <MediaPlanNameTextfield />
          </Stack>
        </Grid>
        <Grid item xs={12} md={5} display="flex" gap={1}>
          <Stack direction="row" spacing={1} flex={1} justifyContent="end">
            {getButtonLabel() && (
              <Stack
                fontSize="14px"
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="center"
                color="text.disabled"
                flex={1}
              >
                <Icon name="calendarAddOn" fill="inherit" />
                <b>{getButtonLabel()}</b>
              </Stack>
            )}
            <HelpDrawer
              faqs={faqs}
              maxMessageLength={1000}
              minMessageLength={10}
              onSubmit={handleAskQuestion}
            />
            {isBooked ? (
              <DuplicateMediaPlan />
            ) : (
              <MediaPlanMenu
                mediaPlanId={mediaPlanId!}
                name={name}
                onInvite={() => setInviteOpen(true)}
                onDeleteMediaPlan={triggerDeleteDialog}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box
      mb={{ xs: 0 }}
      maxWidth="100svw"
      position="sticky"
      left={0}
      top={0}
      zIndex={1}
    >
      {renderHeader}
      <MediaPlanShareModal
        open={inviteOpen}
        onClose={handleInviteClose}
        onSendInvitations={handleSendInvitations}
      />

      {isDeleteDialogOpen && (
        <WarningMessageDialog
          messageTitle="Delete Media Plan"
          messageDescription={`Are you sure you want to permanently delete ${name}?`}
          onClose={() => setIsDeleteDialogOpen(false)}
          onSubmit={handleDeleteMediaPlan}
          caption="Delete"
        />
      )}

      {isDeleteSuccessDialogOpen && (
        <SuccessMessageDialog
          messageTitle="Media Plan Deleted"
          messageDescription={`${name} media plan has been deleted.`}
          onClose={handleDeleteSuccessDialogClose}
          withCloseButton={false}
        />
      )}

      {showSuccessDialog && (
        <SuccessMessageDialog
          messageTitle="Invitation Sent Successfully"
          messageDescription="Your collaborator will receive the details to join the media plan."
          onClose={() => setShowSuccessDialog(false)}
        />
      )}
    </Box>
  );
}
