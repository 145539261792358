import {
  MediaPlanData,
  MediaPlanResponseData,
  Profile,
  SponsorshipOption,
} from "./types";

/**
 * Converts API response data into frontend-friendly media plan data structure
 * @param response The API response data
 * @returns Frontend-friendly media plan data
 */
export function prepareMediaPlan(
  response: MediaPlanResponseData
): MediaPlanData {
  return {
    discountPercent: response.discount_percent,
    discountPrice: response.discount_price,
    name: response.name,
    profiles: response.profiles.map<Profile>((profile) => ({
      id: profile.id,
      slug: profile.slug,
      logo: profile.logo,
      name: profile.name,
      subscribers: profile.subscribers,
      sponsorshipOptions: profile.sponsorship_options.map<SponsorshipOption>(
        (sponsorshipOption) => ({
          id: sponsorshipOption.id,
          uuid: sponsorshipOption.uuid,
          description: sponsorshipOption.description,
          name: sponsorshipOption.name,
          price: sponsorshipOption.price,
          quantity: sponsorshipOption.quantity,
          regularPrice: sponsorshipOption.regular_price,
          cpc: sponsorshipOption.cpc,
          clickRate: sponsorshipOption.click_rate,
          dates: sponsorshipOption.dates.map((date) => ({
            date: date.date,
            price: date.price,
            oldPrice: date.old_price,
            oldDate: date.old_date,
          })),
          sponsorships: sponsorshipOption.sponsorships,
          regularPricePerUnit: sponsorshipOption.regular_price_per_unit,
        })
      ),
    })),
    regularPrice: response.regular_price,
    totalPrice: response.total_price,
    first_sponsorship_date: response.first_sponsorship_date,
    last_sponsorship_date: response.last_sponsorship_date,
    isBooked: response.booked,
    status: response.status,
    datesChanged: response.dates_changed,
    pricesChanged: response.prices_changed,
  };
}

/**
 * Formats a number as currency with specified options
 * @param number The number to format
 * @param decimals Maximum number of decimal places
 * @param minDecimals Minimum number of decimal places
 * @param currency The currency code (default: USD)
 * @param notation The number notation style
 * @param includeCurrency Whether to include the currency symbol
 * @returns Formatted currency string
 */
export function formatNumberAsCurrency(
  number: number,
  decimals = 2,
  minDecimals = decimals,
  currency = "USD",
  notation: "standard" | "scientific" | "engineering" | "compact" = "standard",
  includeCurrency = true
): string {
  return new Intl.NumberFormat("en-US", {
    style: includeCurrency ? "currency" : "decimal",
    notation,
    currency: includeCurrency ? currency : undefined,
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

/**
 * Parses a date string in the current timezone
 * @param dateStr The date string to parse (ISO 8601 format)
 * @returns Date object in current timezone
 */
export function parseDateInCurrentTZ(dateStr: string): Date {
  return new Date(dateStr.replace(/z/i, ""));
}

/**
 * Validates if a date string is in ISO 8601 format
 * @param dateStr The date string to validate
 * @returns boolean indicating if the date is valid
 */
export function isValidISODate(dateStr: string): boolean {
  const date = new Date(dateStr);
  return date instanceof Date && !isNaN(date.getTime());
}

/**
 * Formats a price in cents to a displayable currency string
 * @param cents The price in cents
 * @returns Formatted currency string
 */
export function formatCentsAsCurrency(cents: number): string {
  return formatNumberAsCurrency(cents / 100);
}
