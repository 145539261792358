import { useInstantSearch, useStats } from "react-instantsearch";
import type { IndexUiState } from "instantsearch.js";
import { INDEX_NAME } from "./SortBy";

export default function CustomStats({
  hasActiveFilters,
}: {
  hasActiveFilters: (state: IndexUiState) => boolean;
}) {
  const { results, uiState, status } = useInstantSearch();
  const state = uiState[INDEX_NAME];

  const hasUserFilters = hasActiveFilters(state);

  const noResults = !results.__isArtificial && results.nbHits === 0;

  const { nbHits } = useStats();

  const hideResults = !results.query && !hasUserFilters;
  const isLoading = status === "loading" || status === "stalled";

  if (isLoading || hideResults) return null;

  if (results.query && noResults) return <div>No results</div>;

  return (
    <>
      {!results.__isArtificial && (
        <span>
          {`${nbHits.toLocaleString()} ${
            nbHits === 1 ? "result" : "results"
          } found`}
        </span>
      )}
    </>
  );
}
