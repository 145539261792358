import { Box } from "@mui/material";
import axios from "axios";
import useComponentDidMount from "hooks/useComponentDidMount";
import { useIsMobile } from "hooks/useIsMobile";
import noop from "lodash/noop";
import { useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../context";
import { useRefreshOnTabFocus } from "../../../hooks/useRefreshOnTabFocus";
import { segmentsTrack } from "../../../utils/analytics";
import CompletedMediaPlan from "./components/layout/completed";
import Confirmation from "./components/Confirmation";
import DratMediaPlan from "./components/layout/draft";
import InProgressMediaPlan from "./components/layout/inProgress";
import Recommendations from "./components/layout/recommendations/Recommendations";
import { MediaPlanContext } from "./context";
import { MediaPlanData, MediaPlanStatus } from "./types";
import { prepareMediaPlan } from "./utils";

import PriceInfo from "./components/layout/PriceInfo";
import ProfileSearch from "./components/layout/ProfileSearch";
function countSponsorshipsQty(profiles: MediaPlanData["profiles"]) {
  return profiles.reduce(
    (acc, profile) =>
      acc +
      profile.sponsorshipOptions?.reduce((acc, sponsorship) => {
        return acc + sponsorship.quantity;
      }, 0),
    0
  );
}
export default function MediaPlan() {
  const { mediaPlanId } = useParams();
  const {
    setBreadcrumbLabel,
    userData: { companyId },
  } = useContext(UserContext);
  const [mediaPlan, setMediaPlan] = useState<MediaPlanData | null>(null);
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const isMobile = useIsMobile();

  const sponsorshipsQty = mediaPlan
    ? countSponsorshipsQty(mediaPlan.profiles)
    : 0;

  const fetchData = useCallback(() => {
    axios
      .get(`/api/v1/advertiser/media_plans/${mediaPlanId}`)
      .then((res) => {
        const mediaPlanData = res.data;
        setBreadcrumbLabel(mediaPlanData.name);
        setMediaPlan(prepareMediaPlan(mediaPlanData));
      })
      .catch(noop);
  }, [mediaPlanId, setBreadcrumbLabel]);

  useComponentDidMount(fetchData);
  useRefreshOnTabFocus(fetchData);

  const objectIDs =
    mediaPlan?.profiles.map((profile) => String(profile.id)) || [];

  const showRecommendations = !isMobile && !mediaPlan?.isBooked && !!companyId;

  const renderMediaPlan = () => {
    switch (mediaPlan?.status) {
      case MediaPlanStatus.COMPLETED:
        return <CompletedMediaPlan />;
      case MediaPlanStatus.IN_PROGRESS:
        return <InProgressMediaPlan />;
      case MediaPlanStatus.DRAFT:
      default:
        return (
          <DratMediaPlan onAddSponsorshipClick={() => setSidePanelOpen(true)} />
        );
    }
  };

  return mediaPlan ? (
    <MediaPlanContext.Provider value={{ ...mediaPlan, setMediaPlan }}>
      <Box
        display="flex"
        flexDirection="column"
        pb={isMobile || mediaPlan.isBooked ? 0 : "100px"}
      >
        <Confirmation
          mediaPlanName={mediaPlan.name}
          open={confirmationDialogOpen}
          onClose={() => setConfirmationDialogOpen(false)}
          onConfirm={() => {
            segmentsTrack("media_plan_booked", {
              media_plan_name: mediaPlan.name,
              media_plan_booking_period_from: mediaPlan.first_sponsorship_date,
              media_plan_booking_period_until: mediaPlan.last_sponsorship_date,
              media_plan_uuid: mediaPlanId,
              newsletter_count: mediaPlan.profiles.length,
              sponsorship_count: sponsorshipsQty,
              discount_amount: mediaPlan.discountPrice,
              discount_percentage: mediaPlan.discountPercent,
              media_plan_budget_before_discount: mediaPlan.regularPrice,
              media_plan_budget_after_discount: mediaPlan.totalPrice,
            });

            setConfirmationDialogOpen(false);
          }}
          title={mediaPlan.name}
          regularPrice={mediaPlan.regularPrice}
          totalPrice={mediaPlan.totalPrice}
          discountPrice={mediaPlan.discountPrice}
          discountPercent={mediaPlan.discountPercent}
          sponsorshipsQty={sponsorshipsQty}
        />
        <ProfileSearch
          open={sidePanelOpen}
          onClose={() => setSidePanelOpen(false)}
        />

        {renderMediaPlan()}

        {showRecommendations && (
          <Recommendations companyId={companyId} objectIDs={objectIDs} />
        )}
      </Box>
      {!mediaPlan.isBooked && (
        <PriceInfo
          booked={mediaPlan.isBooked}
          regularPrice={mediaPlan.regularPrice}
          totalPrice={mediaPlan.totalPrice}
          discountPrice={mediaPlan.discountPrice}
          discountPercent={mediaPlan.discountPercent}
          onAddSponsorshipClick={() => setSidePanelOpen(true)}
          onBookClick={() => {
            segmentsTrack("media_plans_book_button_click", {
              media_plan_name: mediaPlan.name,
              media_plan_uuid: mediaPlanId,
            });
            setConfirmationDialogOpen(true);
          }}
        />
      )}
    </MediaPlanContext.Provider>
  ) : null;
}
