import React, { ReactNode } from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

export type MediaPlansTableRowData = {
  name: string;
  data: (string | ReactNode)[];
};

export type MediaPlansTableItem = {
  uuid: string;
  name: string;
  data: (string | ReactNode)[];
};

export type MediaPlansTableBodyProps = {
  items: MediaPlansTableItem[];
};

function MediaPlansTableRow({ name, data }: MediaPlansTableRowData) {
  return (
    <Grid
      container
      alignItems="center"
      sx={{
        p: 2,
        "&:hover": { bgcolor: "#F8FAFF" },
        "&:hover .mp-name": { color: "primary.main" },
        fontSize: 16,
      }}
    >
      <Grid item xs={6} fontWeight="bold" className="mp-name">
        {name}
      </Grid>
      {data.map((item: string | ReactNode, index: number) => (
        <Grid
          item
          xs={6 / data.length}
          key={index}
          sx={{
            textAlign: "center",
            color: "text.secondary",
          }}
        >
          {item}
        </Grid>
      ))}
    </Grid>
  );
}

export default function MediaPlansTableBody({
  items,
}: MediaPlansTableBodyProps) {
  return (
    <>
      {items.map((item: MediaPlansTableItem) => (
        <Link
          to={`/advertiser/media_plans/${item.uuid}`}
          key={item.uuid}
          style={{ color: "inherit", margin: "0 16px" }}
        >
          <MediaPlansTableRow name={item.name} data={item.data} />
        </Link>
      ))}
    </>
  );
}
