import { Avatar, Stack, Typography } from "@mui/material";
import { format, fromUnixTime } from "date-fns";
import { Icon, IconChip } from "shared-components";
import { Profile, Sponsorship } from "../../../types";
import ActionRequiredButton from "../../common/ActionRequiredButton";
import { PriceDisplay } from "../../common/PriceDisplay";
import { GridCell } from "../../common/GridRow";
export const getProfileGridItems = (
  profile: Profile,
  totalProfilePrice: number
): GridCell[] => [
  {
    id: `logo-${profile.slug}`,
    xs: 0.5,
    content: (
      <Avatar
        src={profile.logo}
        variant="circular"
        sx={{ width: 28, height: 28 }}
      />
    ),
  },
  {
    id: `info-${profile.slug}`,
    xs: 5.5,
    content: (
      <Stack direction="row" gap="12px" alignItems="center">
        <Typography
          noWrap
          fontWeight="bold"
          sx={{ cursor: "pointer", color: "primary.main" }}
          onClick={(e) => {
            e.preventDefault();
            window.open(`/sites/${profile.slug}`, "_blank");
          }}
        >
          {profile.name}
        </Typography>
        <IconChip
          icon={<Icon name="subscribers" />}
          label={profile.subscribers.toLocaleString("en-US")}
          labelClassName="chip-label"
        />
      </Stack>
    ),
  },
  { id: `spacer-${profile.slug}`, xs: 2, content: null },
  {
    id: `price-${profile.slug}`,
    xs: 2,
    content: <PriceDisplay price={totalProfilePrice} bold />,
    sx: { textAlign: "center" },
  },
  { id: `spacer2-${profile.slug}`, xs: 2, content: null },
];

export const getSponsorshipGridItems = (
  sponsorship: Sponsorship,
  name: string
): GridCell[] => {
  const runDateObj = sponsorship.run_date
    ? fromUnixTime(sponsorship.run_date)
    : 0;

  return [
    {
      id: `name-${sponsorship.id}`,
      xs: 5.5,
      content: (
        <Typography
          variant="body1"
          pl={6}
          fontSize={16}
          maxWidth="fit-content"
          className="sponsorship-date"
          sx={{
            cursor: "pointer",
            "&:hover": {
              color: "primary.main",
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            window.open(`/advertiser/campaigns/${sponsorship.id}`, "_blank");
          }}
        >
          {name}
        </Typography>
      ),
    },
    {
      id: `date-${sponsorship.id}`,
      xs: 2.5,
      content: (
        <Typography variant="body1" textAlign="center">
          {!!sponsorship.run_date
            ? format(runDateObj, "EEEE, do MMMM yyyy")
            : "-"}
        </Typography>
      ),
    },
    {
      id: `price-${sponsorship.id}`,
      xs: 2,
      content: (
        <PriceDisplay
          price={sponsorship.price}
          priceWithoutDiscount={undefined}
        />
      ),
    },
    {
      id: `action-${sponsorship.id}`,
      xs: 2,
      content: (
        <ActionRequiredButton
          id={sponsorship.id}
          status={sponsorship.status}
          review_link={sponsorship.review_link}
          type={sponsorship.type}
        />
      ),
    },
  ];
};
