import { useMatch } from "react-router-dom";
import { Link as MuiLink, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

export default function Header({
  title,
  description,
}: {
  title: string;
  description?: string;
}) {
  const isSearchPage = useMatch({ path: "/search", end: true });
  const location = useLocation();
  const navigate = useNavigate();

  if (!title && !description && !isSearchPage) {
    return null;
  }

  const handleReturnToMarketplace = () => {
    if (location.state?.from) {
      navigate(location.state?.from);
    } else {
      navigate("/marketplace");
    }
  };

  return (
    <div className="category">
      <MuiLink
        onClick={handleReturnToMarketplace}
        className="back"
        underline="none"
        sx={{ color: "primary.dark", cursor: "pointer" }}
      >
        Back to Marketplace
      </MuiLink>

      {title && <h2 className="heading">{title}</h2>}
      {description && <Typography variant="body1">{description}</Typography>}
    </div>
  );
}
