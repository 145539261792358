import { Box } from "@mui/material";
import React, { useState } from "react";
import { Outlet, useParams } from "react-router-dom";

import CampaignList from "./CampaignList";
import OptOutBanner from "./OptOutBanner";
import { useIsMobile } from "hooks/useIsMobile";

const MobileView: React.FC<{ campaignId?: string }> = ({ campaignId }) => {
  if (campaignId) {
    return <Outlet />;
  }
  return <CampaignList />;
};

const DesktopView: React.FC<{ campaignId?: string }> = ({ campaignId }) => {
  return (
    <>
      <CampaignList />
      {campaignId && <Outlet />}
    </>
  );
};

const InboxChatContent: React.FC = () => {
  const isMobile = useIsMobile();
  const { campaignId } = useParams<{ campaignId: string }>();
  const [showOptOutBanner, setShowOptOutBanner] = useState<boolean>(
    localStorage.getItem("showOptOutBanner") !== "false"
  );

  const handleClose = () => {
    localStorage.setItem("showOptOutBanner", "false");
    setShowOptOutBanner(false);
  };

  return (
    <>
      <OptOutBanner
        showOptOutBanner={showOptOutBanner}
        handleClose={handleClose}
      />
      <Box
        display="flex"
        height={`calc(100svh - ${showOptOutBanner ? "168px" : "104px"})`}
        gap={1.5}
      >
        {isMobile ? (
          <MobileView campaignId={campaignId} />
        ) : (
          <DesktopView campaignId={campaignId} />
        )}
      </Box>
    </>
  );
};

export default InboxChatContent;
