import { Box, Divider, Stack } from "@mui/material";
import { Paper } from "components/MUIWrappers";
import React, { useContext } from "react";
import { MediaPlanContext } from "../../context";
import { Profile } from "../../types";
import HeaderCell from "../common/HeaderCell";
import TableHeaderWrapper from "../common/TableHeaderWrapper";
import EmptyState from "./EmptyState";
import MediaPlanInfo from "./MediaPlanInfo";
import MediaPlanTotalRow from "../common/MediaPlanTotalRow";
import Notifications from "../common/Notification";

interface HeaderConfig {
  label: string;
  xs: number;
  textAlign?: string;
  borderRight?: boolean;
  pl?: string;
}

interface MediaPlanLayoutProps {
  headerConfig: HeaderConfig[];
  profiles: Profile[];
  totalPrice: number;
  renderRow: (profile: Profile) => JSX.Element;
  withDivider?: boolean;
  subscribersCount: number | null;
  onAddSponsorshipClick?: () => void;
}

const MediaPlanLayout: React.FC<MediaPlanLayoutProps> = ({
  headerConfig,
  profiles,
  totalPrice,
  renderRow,
  withDivider = false,
  subscribersCount,
  onAddSponsorshipClick,
}) => {
  const { isBooked, datesChanged, pricesChanged } =
    useContext(MediaPlanContext)!;

  return (
    <>
      <Notifications
        datesChanged={datesChanged}
        pricesChanged={pricesChanged}
      />
      <Paper
        sx={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          minHeight: {
            xs: "calc(100vh - 95px)",
            md: isBooked ? "calc(100vh - 145px)" : "60svh",
          },
        }}
      >
        <MediaPlanInfo />
        <Box
          sx={{
            minWidth: "1000px",
            flex: 1,
            maxHeight: {
              md: "unset",
              xs: isBooked ? "calc(100vh - 215px)" : "calc(100vh - 95px)",
            },
            overflow: { xs: "auto", md: "unset" },
            display: "flex",
            flexDirection: "column",
            mt: { xs: 0, md: 2 },
          }}
        >
          <TableHeaderWrapper>
            {headerConfig.map((header, index) => (
              <HeaderCell
                key={index}
                xs={header.xs}
                pl={header.pl}
                textAlign={header.textAlign as "left" | "center" | "right"}
                borderRight={header.borderRight}
              >
                {header.label}
              </HeaderCell>
            ))}
          </TableHeaderWrapper>
          {profiles.length === 0 ? (
            <EmptyState onAddSponsorshipClick={onAddSponsorshipClick} />
          ) : (
            <Stack
              px={2}
              my={3}
              minWidth="1000px"
              pb={{ xs: isBooked ? 0 : "200px", md: 2 }}
            >
              {profiles.map((profile) => (
                <React.Fragment key={profile.id}>
                  {renderRow(profile)}
                  {withDivider && <Divider />}
                </React.Fragment>
              ))}

              {isBooked && (
                <MediaPlanTotalRow
                  subscribersCount={subscribersCount}
                  totalPrice={totalPrice}
                />
              )}
            </Stack>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default MediaPlanLayout;
