import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Icon from "../Icon";
import { Button, TextField } from "../MUIWrappers";
import CommonDrawer from "./CommonDrawer";
import { formatWithCommas } from "../utils";

interface FAQItem {
  id: number;
  question: string;
  answer: string;
}

interface HelpDrawerProps {
  faqs: FAQItem[];
  maxMessageLength: number;
  minMessageLength: number;
  onSubmit: (message: string, mediaPlanId?: string) => void;
}

const HelpDrawer: React.FC<HelpDrawerProps> = ({
  faqs,
  maxMessageLength,
  minMessageLength,
  onSubmit,
}) => {
  const { mediaPlanId } = useParams();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const error =
    message.length > maxMessageLength
      ? `Message exceeds ${formatWithCommas(maxMessageLength)} characters`
      : message.length < minMessageLength && message.length > 0
        ? `Message must be at least ${minMessageLength} characters`
        : undefined;

  return (
    <>
      {isMobile ? (
        <Button
          variant="outlined"
          onClick={() => setOpen(true)}
          sx={{ minWidth: 40, width: 40, p: 0 }}
        >
          <Icon name="question" width={24} height={24} fill="inherit" />
        </Button>
      ) : (
        <Button
          variant="outlined"
          onClick={() => setOpen(true)}
          startIcon={
            <Icon name="question" width={24} height={24} fill="inherit" />
          }
        >
          Need Help
        </Button>
      )}
      <CommonDrawer
        open={open}
        onClose={() => setOpen(false)}
        title="Ask a Question"
        actions={[
          {
            caption: "Cancel",
            onClick: () => setOpen(false),
            type: "button",
          },
          {
            disabled: !message.length || !!error,
            caption: "Send Message",
            onClick: () => {
              onSubmit(message, mediaPlanId);
              setOpen(false);
            },
            type: "submit",
          },
        ]}
      >
        <Stack p={2} gap={2} direction="column">
          <Typography fontWeight="bold">
            Need Assistance? Our Sales Team is Here to Help!
          </Typography>
          <Typography>
            Could you share the specific challenge you're looking to address?
          </Typography>

          <TextField
            multiline
            rows={10}
            fullWidth
            placeholder="Please describe your concern in detail. The more info, the better we can assist."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setMessage(e.target.value)
            }
            error={!!error}
            helperText={
              error ||
              `${formatWithCommas(message.length)}/${formatWithCommas(maxMessageLength)}`
            }
          />

          <Stack spacing={1} mt={2}>
            <Typography fontWeight="bold" mb={2}>
              Frequently Asked Questions
            </Typography>
            {faqs.map((faq) => (
              <Accordion
                key={faq.id}
                elevation={0}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                  borderRadius: "8px !important",
                  border: "1px solid #D4D6E4",
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography fontWeight="500" color="text.secondary">
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="text.secondary">{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
        </Stack>
      </CommonDrawer>
    </>
  );
};

export default HelpDrawer;
