import { DifferenceOutlined } from "@mui/icons-material";
import axios from "axios";
import { noop } from "lodash";
import { MediaPlanContext } from "pages/advertiser/mediaplan/context";
import { prepareMediaPlan } from "pages/advertiser/mediaplan/utils";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ConfirmationMessageDialog } from "shared-components";

function DuplicateMediaPlan() {
  const { mediaPlanId } = useParams();
  const { setMediaPlan } = useContext(MediaPlanContext)!;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const { data } = await axios.post(
        `/api/v1/advertiser/media_plans/${mediaPlanId}/duplicate`
      );
      navigate(`/advertiser/media_plans/${data.uuid}`);
      setMediaPlan(prepareMediaPlan(data));
    } catch (error) {
      noop();
    } finally {
      setOpen(false);
    }
  };
  return (
    <>
      <Button
        disableElevation
        variant="contained"
        sx={{ minWidth: 40, width: 40, p: 0 }}
        onClick={() => setOpen(true)}
      >
        <DifferenceOutlined />
      </Button>
      <ConfirmationMessageDialog
        open={open}
        onClose={() => setOpen(false)}
        variant="question"
        messageTitle="Duplicate Media Plan?"
        messageDescription="The media plan will be duplicated with all placements, but dates will not be included.
You can add dates after duplication."
        actions={[
          {
            caption: "Cancel",
            type: "button",
            onClick: () => setOpen(false),
          },
          {
            caption: "Confirm",
            onClick: handleSubmit,
            type: "submit",
          },
        ]}
      />
    </>
  );
}

export default DuplicateMediaPlan;
