import { Stack, Typography, Avatar, GridProps } from "@mui/material";
import { Profile, SponsorshipOption, Sponsorship } from "../../../types";
import { StatusChip } from "shared-components";
import { PriceDisplay } from "../../common/PriceDisplay";
import { GridCell } from "../../common/GridRow";
import ActionRequiredButton from "../../common/ActionRequiredButton";
import {
  formatDistance,
  fromUnixTime,
  format,
  differenceInCalendarDays,
} from "date-fns";
import SubscribersQty from "../../common/SubscribersQty";

export const getProfileRowCells = (profile: Profile): GridCell[] => {
  const totalProfilePrice = profile.sponsorshipOptions.reduce(
    (acc: number, curr: SponsorshipOption) => acc + curr.price,
    0
  );

  //TODO: should be total from all sponsorships regular price per unit
  const totalProfilePriceWithoutDiscount = profile.sponsorshipOptions.reduce(
    (acc: number, curr: SponsorshipOption) => acc + curr.regularPrice,
    0
  );

  return [
    {
      id: `avatar-${profile.id}`,
      xs: 0.5,
      content: (
        <Avatar
          src={profile.logo}
          variant="circular"
          sx={{ width: 28, height: 28 }}
        />
      ),
    },
    {
      id: `profile-info-${profile.id}`,
      xs: 4.5,
      content: (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            noWrap
            fontWeight="bold"
            sx={{ cursor: "pointer", color: "primary.main" }}
            onClick={(e) => {
              e.stopPropagation();
              window.open(`/sites/${profile.slug}`, "_blank");
            }}
          >
            {profile.name}
          </Typography>
          <Typography variant="caption">{`(${profile.sponsorshipOptions.length})`}</Typography>
          <SubscribersQty subscribers={profile.subscribers} />
        </Stack>
      ),
    },
    { id: `spacer-1-${profile.id}`, xs: 1.5, content: null },
    { id: `spacer-2-${profile.id}`, xs: 1.5, content: null },
    {
      id: `total-price-${profile.id}`,
      xs: 2,
      content: (
        <PriceDisplay
          bold
          price={totalProfilePrice}
          priceWithoutDiscount={totalProfilePriceWithoutDiscount}
        />
      ),
      textAlign: "center" as GridProps["textAlign"],
    },
  ];
};

export const getSponsorshipRowCells = (
  sponsorshipOptions: SponsorshipOption
): GridCell[] => [
  { id: `spacer-${sponsorshipOptions.id}`, xs: 0.5, content: null },
  {
    id: `sponsorship-name-${sponsorshipOptions.id}`,
    xs: 6,
    content: (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography noWrap fontWeight="bold">
          {sponsorshipOptions.name}
        </Typography>
        <Typography variant="caption">{`(${sponsorshipOptions.sponsorships.length})`}</Typography>
      </Stack>
    ),
  },
  { id: `spacer-1-${sponsorshipOptions.id}`, xs: 1.5, content: null },
  {
    id: `sponsorship-price-${sponsorshipOptions.id}`,
    xs: 2,
    content: (
      <PriceDisplay
        bold
        price={sponsorshipOptions.price}
        priceWithoutDiscount={sponsorshipOptions.regularPrice}
      />
    ),
    textAlign: "center" as GridProps["textAlign"],
  },
];

export const getDatesRowCells = (
  sponsorship: Sponsorship,
  today: Date = new Date()
): GridCell[] => {
  const dateObj = sponsorship.suggested_or_agreed_date
    ? new Date(sponsorship.suggested_or_agreed_date)
    : 0;
  const deadlineObj = sponsorship.deadline
    ? fromUnixTime(sponsorship.deadline)
    : 0;

  const isOutOfDateRange = deadlineObj
    ? differenceInCalendarDays(deadlineObj, today) < 3
    : false;

  return [
    {
      id: "weekday",
      xs: 5,
      sx: { pl: "72px" },
      content: (
        <Typography
          fontSize={16}
          maxWidth="fit-content"
          sx={{
            cursor: "pointer",
            "&:hover": {
              color: "primary.main",
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            window.open(`/advertiser/campaigns/${sponsorship.id}`, "_blank");
          }}
        >
          {format(dateObj, "EEE, do MMMM yyyy")}
        </Typography>
      ),
    },

    {
      id: "status",
      xs: 1.5,
      sx: { textAlign: "center" },
      content: <StatusChip status={sponsorship.status} />,
    },
    {
      id: "deadline",
      xs: 1.5,
      sx: {
        textAlign: "center",
        color: isOutOfDateRange ? "error.main" : "inherit",
      },
      content: (
        <Typography fontSize={16}>
          {deadlineObj
            ? formatDistance(deadlineObj, today, {
                addSuffix: true,
              })
            : "-"}
        </Typography>
      ),
    },
    {
      id: "price",
      xs: 2,
      content: sponsorship.price ? (
        <PriceDisplay price={sponsorship.price} />
      ) : (
        "-"
      ),
    },
    {
      id: "action-required",
      xs: 2,
      content: sponsorship.action_required ? (
        <ActionRequiredButton
          id={sponsorship.id}
          status={sponsorship.status}
          review_link={sponsorship.review_link}
          type={sponsorship.type}
        />
      ) : null,
    },
  ];
};
