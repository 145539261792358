// helpers.ts
import { format, addDays, differenceInCalendarDays } from "date-fns";

export const formatDateKey = (date: Date) => format(date, "yyyy-MM-dd");

export const computePricesByDate = (
  availabilities: {
    prices: { date: string; price: number }[];
    normal_price: number;
    min_date: string;
    max_date: string;
    unavailable_dates: string[];
  },
  _sponsorshipOption: any // kept for type compatibility; not used in computation
): Record<string, { price: number; isSmart: boolean }> | null => {
  if (!availabilities?.prices?.length) return null;

  const result = availabilities.prices.reduce<
    Record<string, { price: number; isSmart: boolean }>
  >((acc, item) => {
    const dateKey = item.date.split("T")[0];
    if (!availabilities.unavailable_dates.includes(dateKey)) {
      acc[dateKey] = {
        price: item.price,
        isSmart: item.price < availabilities.normal_price,
      };
    }
    return acc;
  }, {});

  const startDate = new Date(availabilities.min_date);
  const endDate = new Date(availabilities.max_date);
  const diff = differenceInCalendarDays(endDate, startDate);

  for (let i = 0; i <= diff; i++) {
    const dateKey = formatDateKey(addDays(startDate, i));
    if (
      !result[dateKey] &&
      !availabilities.unavailable_dates.includes(dateKey)
    ) {
      result[dateKey] = {
        price: availabilities.normal_price || 0,
        isSmart: false,
      };
    }
  }
  return result;
};
