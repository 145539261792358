import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker, isSameDay } from "react-dates";
import "./react_dates_overrides.scss";
import { Moment } from "moment";
import { useEffect, useState } from "react";
import { useRange } from "react-instantsearch";
import moment from "moment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useIsMobile } from "hooks/useIsMobile";

const blockedDatesList = [moment(), moment().add(1, "days")];

const isDayBlocked = (day1: Moment) =>
  blockedDatesList.some((day2) => isSameDay(day1, day2));

const convertToMoment = (timestamp: number | undefined) =>
  Number.isFinite(timestamp) ? moment.unix(timestamp as number) : null;

export default function DatePanel() {
  const { start, refine } = useRange({ attribute: "sponsored_email_dates" });
  const isMobile = useIsMobile();
  const upToLg = !useMediaQuery("(min-width:1024px)");

  const [from, to] = start;
  const [startDate, setStartDate] = useState(convertToMoment(from));
  const [endDate, setEndDate] = useState(convertToMoment(to));

  useEffect(() => {
    setStartDate(convertToMoment(from));
    setEndDate(convertToMoment(to));
  }, [from, to]);

  const [focusedInput, setFocusedInput] = useState(null);

  const onDateChange = (dates: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);

    if (dates.startDate === null && dates.endDate === null) {
      refine([undefined, undefined]);
    }

    if (dates.startDate && dates.endDate) {
      const startTimestamp = dates.startDate.unix();
      const endTimestamp = dates.endDate.unix();
      refine([startTimestamp, endTimestamp]);
    }
  };

  const onFocusChange = (focusedInput: any) => {
    setFocusedInput(focusedInput);
  };

  const renderDayContents = (day: Moment) => {
    const isBoundaryDay =
      (startDate && day.isSame(startDate, "day")) ||
      (endDate && day.isSame(endDate, "day"));

    return (
      <div className="day-container">
        <div className={isBoundaryDay ? "start-end-circle" : undefined}>
          {day.format("D")}
        </div>
      </div>
    );
  };

  return (
    <div>
      <DateRangePicker
        startDate={startDate}
        startDateId="start_date_id"
        endDate={endDate}
        endDateId="end_date_id"
        onDatesChange={onDateChange}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        showDefaultInputIcon
        small
        displayFormat="MMM D"
        daySize={36}
        hideKeyboardShortcutsPanel
        showClearDates
        isDayBlocked={isDayBlocked}
        readOnly
        numberOfMonths={isMobile ? 1 : 2}
        withPortal={upToLg ? true : false}
        renderDayContents={renderDayContents}
        withFullScreenPortal={isMobile}
      />
    </div>
  );
}
