import React from "react";
import { Button as MUIButton, ButtonProps } from "@mui/material";
import { theme } from "shared-components";

// theme.shape.borderRadius === 4
const borderRadius = {
  xs: 1,
  md: 2,
};

// theme.spacing(1) === 8
const p = {
  xs: 1,
  md: 2,
};

const maxHeight = {
  xs: theme.spacing(6),
};

export function Button(props: ButtonProps) {
  return (
    <MUIButton
      {...props}
      sx={{
        borderRadius,
        p,
        maxHeight,
        ...props.sx,
      }}
    />
  );
}
