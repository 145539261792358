import { Paper as MUIPaper, PaperProps } from "@mui/material";
import { useIsMobile } from "../../hooks/useIsMobile";
type Size = "small" | "medium" | "large";

const borderRadiusVariants = {
  small: "4px",
  medium: "8px",
  large: "16px",
};

const boxShadowVariants = {
  small: "0 18px 40px 0 rgba(52, 77, 131, 0.08)",
  medium: "0 18px 40px 0 rgba(52, 77, 131, 0.08)",
  large: "0 18px 40px 0 rgba(52, 77, 131, 0.08)",
};

export function Paper(props: PaperProps & { size?: Size }) {
  const isMobile = useIsMobile();
  const borderRadius = isMobile
    ? 0
    : borderRadiusVariants[props.size ?? "medium"];
  const boxShadow = boxShadowVariants[props.size ?? "medium"];

  return <MUIPaper {...props} sx={{ ...props.sx, borderRadius, boxShadow }} />;
}
