import { SponsorshipOption } from "../../../types";
import GridRow from "../../common/GridRow";
import DatesRow from "./DatesRow";
import { getSponsorshipRowCells } from "./gridItems";

function SponsorshipRow({
  sponsorshipOptions,
}: {
  sponsorshipOptions: SponsorshipOption;
}) {
  return (
    <GridRow
      expandable={sponsorshipOptions.sponsorships.length > 0}
      cells={getSponsorshipRowCells(sponsorshipOptions)}
      expandedContent={
        <>
          {sponsorshipOptions.sponsorships.map((sponsorship) => (
            <DatesRow key={sponsorship.id} sponsorship={sponsorship} />
          ))}
        </>
      }
    />
  );
}

export default SponsorshipRow;
