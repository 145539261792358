import { Add } from "@mui/icons-material";
import {
  Grid,
  ToggleButton as MUIToggleButton,
  Stack,
  ToggleButtonProps,
} from "@mui/material";
import axios from "axios";
import { LayoutContext } from "context";
import { format } from "date-fns";
import noop from "lodash/noop";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, HelpDrawer } from "shared-components";
import { MediaPlanStatus } from "../types";

const statusesList: { key: MediaPlanStatus; label: string }[] = [
  { key: "draft", label: "Draft" },
  { key: "in_progress", label: "In Progress" },
  { key: "completed", label: "Completed" },
];

const faqs = [
  {
    id: 1,
    question: "How a media plan works?",
    answer:
      "A media plan simplifies the sponsorship booking process by allowing you to select multiple sponsorships, set your preferred dates, and book everything at once—saving time and streamlining your campaign planning process.",
  },
  {
    id: 2,
    question: "How do I track the performance of my media plan?",
    answer:
      "Once your media plan is booked, you can keep track of the sponsorships through the campaign dashboard here. You can easily track key metrics like open rate, clicks, and conversions to measure effectiveness and optimize future plans.",
  },
  {
    id: 3,
    question: "Can I share my media plan with my team?",
    answer:
      "Yes! Media plans can be shared with your team for collaboration and approval before booking. This ensures everyone is aligned on sponsorship selections and campaign strategy.",
  },
  {
    id: 4,
    question: "Can I edit my media plan after booking?",
    answer:
      "Once a media plan is booked, changes may be limited based on factors like publisher availability, expired offers, and booking restrictions. If adjustments are needed, you may need to create a new media plan or contacting support for assistance.",
  },
];

function Actions() {
  const { setIsDataLoading } = useContext(LayoutContext);
  const navigate = useNavigate();

  async function handleAskQuestion(message: string, mediaPlanId?: string) {
    try {
      await axios.post(
        `/api/v1/advertiser/media_plans/${mediaPlanId}/ask_question`,
        { message }
      );
    } catch (error) {
      noop();
    }
  }

  return (
    <Stack direction="row" spacing={2} justifyContent="end">
      <HelpDrawer
        faqs={faqs}
        maxMessageLength={1000}
        minMessageLength={10}
        onSubmit={handleAskQuestion}
      />
      <Button
        disableElevation
        sx={{ minWidth: 40, width: 40, p: 0 }}
        variant="contained"
        onClick={() => {
          setIsDataLoading(true);
          const newMediaPlanName = format(new Date(), "MM/dd/yyyy HH:mm:ss");
          axios
            .post("/api/v1/advertiser/media_plans", {
              name: newMediaPlanName,
            })
            .then((response) => {
              const { uuid } = response.data;
              navigate(`/advertiser/media_plans/${uuid}`);
            })
            .catch(noop)
            .finally(() => {
              setIsDataLoading(false);
            });
        }}
      >
        <Add />
      </Button>
    </Stack>
  );
}

function ToggleButton(props: ToggleButtonProps) {
  return (
    <MUIToggleButton
      {...props}
      sx={{
        textTransform: "none",
        borderRadius: "50vh",
        mr: "12px",
        px: "20px",
        height: "40px",

        "&.Mui-selected": {
          backgroundColor: "#F0F5FE",
          color: "primary.main",
          border: "1px solid",
          borderColor: "primary.main",
          boxShadow: "0px 2px 4px 0px #EEE7F7CC",
        },
        ":not(.Mui-selected)": {
          backgroundColor: "#F0F5FE",
          border: "none",
          ":hover": {
            backgroundColor: "#4586f61f",
          },
        },
      }}
    />
  );
}

export default function StatusToggle({
  value,
  onChange,
}: {
  value: MediaPlanStatus;
  onChange: (value: MediaPlanStatus) => void;
}) {
  return (
    <Grid container p={2} position="sticky" top={0} left={0}>
      <Grid item xs={12} sm={9}>
        {statusesList.map((status) => (
          <ToggleButton
            color="primary"
            key={status.key}
            value={status.key}
            selected={value === status.key}
            onChange={() => onChange(status.key)}
          >
            {status.label}
          </ToggleButton>
        ))}
      </Grid>
      <Grid item xs={12} sm={3} mt={{ xs: 2, sm: 0 }}>
        <Actions />
      </Grid>
    </Grid>
  );
}
