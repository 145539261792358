import { Grid, Typography } from "@mui/material";

// Reusable component for header cells
function HeaderCell({
  children,
  xs,
  textAlign = "left",
  borderRight = true,
  pb = { xs: "6px", md: "12px" },
  pl,
}: {
  children?: React.ReactNode;
  xs: number;
  textAlign?: "left" | "center" | "right";
  borderRight?: boolean;
  pb?: { xs: string; md: string };
  pl?: string;
}): JSX.Element {
  return (
    <Grid item xs={xs} pb={pb} textAlign={textAlign} pl={pl}>
      <Typography
        noWrap
        fontWeight="bold"
        fontSize="16px"
        borderRight={borderRight ? "1px solid #D4D6E4" : undefined}
      >
        {children}
      </Typography>
    </Grid>
  );
}

export default HeaderCell;
