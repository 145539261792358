import { Alert, Container } from "@mui/material";
import algoliasearch from "algoliasearch/lite";
import axios from "axios";
import { LayoutContext, UserContext } from "context";
import useComponentDidMount from "hooks/useComponentDidMount";
import noop from "lodash/noop";
import { useContext, useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "shared-components";
import { isAdmin } from "utils/authorize";
import includeCustomerIo from "utils/customerIo";
import { UserType } from "../../pages/users/sign-up/types";
import { useIsMobile } from "hooks/useIsMobile";
import { segmentsIdentify, segmentsPageView } from "../../utils/analytics";

export type FlashMessage = {
  message: string;
  severity: "success" | "error" | "warning";
};

const INDEX_NAME = `Site_${process.env.REACT_APP_APP_ENV}`;

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIASEARCH_APP_ID ?? "",
  process.env.REACT_APP_ALGOLIASEARCH_READ_KEY_PUBLIC ?? ""
);

export default function Layout() {
  const location = useLocation();
  const {
    userData,
    setUserData,
    setPavedFee,
    setInboxStatus,
    inboxStatus,
    breadcrumbLabel,
  } = useContext(UserContext);
  const { isDataLoading } = useContext(LayoutContext);
  const [flashMessages, setFlashMessages] = useState<FlashMessage[]>([]);
  const isMobile = useIsMobile();

  const { userType, has_marketplace_access } = userData;

  const currentSearchClient =
    userType === UserType.ADVERTISER && has_marketplace_access
      ? searchClient
      : null;

  const getPavedFee = () => {
    axios
      .get("/api/v1/publisher/sites/fees")
      .then((response) => {
        setPavedFee(response.data);
      })
      .catch(noop);
  };

  const getInboxStatus = () => {
    axios
      .get("/api/v1/chats/status")
      .then((response) => {
        setInboxStatus(response.data);
      })
      .catch(noop);
  };

  useComponentDidMount(() => {
    if (!!userData.firstName) {
      if (userData.userType === "publisher") {
        getPavedFee();
      }
      return;
    }

    axios
      .get("/api/v1/users/me", { withCredentials: true })
      .then((response) => {
        const {
          first_name,
          last_name,
          company_name,
          user_type,
          has_booker_access,
          has_ad_network_access,
          created_at,
          has_marketplace_access,
          uuid,
          email,
          menu,
          ro_id,
          messages,
          company_id,
        } = response.data;

        const userData = {
          firstName: first_name,
          lastName: last_name,
          companyName: company_name,
          userType: user_type,
          createdAt: new Date(created_at),
          isAdmin: isAdmin(),
          has_booker_access,
          has_ad_network_access,
          has_marketplace_access,
          userMenu: menu,
          uuid,
          ro_id,
          email,
          companyId: company_id,
        };

        segmentsIdentify(userData, response.data);
        segmentsPageView();

        includeCustomerIo(email, created_at);

        if (user_type === "publisher") {
          getPavedFee();
        }
        setUserData({ ...userData });
        getInboxStatus();

        const newFlashMessages: FlashMessage[] = [];

        if (messages) {
          const { notice, alert, error } = messages;

          if (notice) {
            newFlashMessages.push({ message: notice, severity: "success" });
          }
          if (alert) {
            newFlashMessages.push({ message: alert, severity: "warning" });
          }
          if (error) {
            newFlashMessages.push({ message: error, severity: "error" });
          }
        }
        setFlashMessages(newFlashMessages);
      })
      .catch(noop);
  });

  useEffect(() => {
    setFlashMessages([]);
  }, [location]);

  return (
    <>
      <Header
        userData={userData}
        inboxStatus={inboxStatus}
        isDataLoading={isDataLoading}
        isEmbedded={false}
        searchClient={currentSearchClient}
        indexName={INDEX_NAME}
        breadcrumbLabel={breadcrumbLabel}
      />
      <div id="cio_inline_2"></div>

      <Container
        fixed={false}
        sx={{ pt: isMobile ? 0 : 2, pb: isMobile ? 0 : 3 }}
        disableGutters={isMobile}
      >
        {flashMessages.map((flashMessage, index) => (
          <Alert
            key={index}
            sx={{
              mb: 2,
              width: "100%",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: `${flashMessage.severity}.main`,
            }}
            severity={flashMessage.severity}
            onClose={() =>
              setFlashMessages((prev) => prev.filter((_, i) => i !== index))
            }
          >
            {flashMessage.message}
          </Alert>
        ))}
        <Outlet />
      </Container>
    </>
  );
}

export { LayoutContext };
