import { Collapse } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { noop } from "lodash";
import { useContext } from "react";
import { MediaPlanContext } from "../../../context";
import { prepareMediaPlan } from "../../../utils";
import GridRow from "../../common/GridRow";
import { getDatesGridCells } from "./gridItems";
import { DateOption } from "pages/advertiser/mediaplan/types";
import { LayoutContext } from "context";

type DatesRowProps = {
  isExpanded: boolean;
  dates?: DateOption[];
  soId: number;
  regularPrice: number;
};

const isPriceIncreased = (price: number, oldPrice: number | null) =>
  oldPrice !== null && price > oldPrice;

function DraftDatesRow({
  isExpanded,
  dates,
  soId,
  regularPrice,
}: DatesRowProps) {
  const { setMediaPlan } = useContext(MediaPlanContext)!;
  const { setIsDataLoading } = useContext(LayoutContext);
  const { mediaPlanId } = useParams();

  const handleRemoveDate = async (date: string) => {
    try {
      const { data } = await axios.delete(
        `/api/v1/advertiser/media_plans/${mediaPlanId}/media_plan_sponsorship_options/remove_date`,
        { params: { so_id: soId, date } }
      );
      setMediaPlan(prepareMediaPlan(data));
    } catch (error) {
      noop();
    }
  };

  const handleAcceptPrice = async (date: string) => {
    try {
      setIsDataLoading(true);
      const { data } = await axios.patch(
        `/api/v1/advertiser/media_plans/${mediaPlanId}/media_plan_sponsorship_options/accept_price`,
        { so_id: soId, date }
      );
      setMediaPlan(prepareMediaPlan(data));
      return data;
    } catch (error) {
      noop();
    } finally {
      setIsDataLoading(false);
    }
  };

  return (
    <Collapse in={isExpanded}>
      {dates?.map(({ date, price, oldPrice, oldDate }) => (
        <GridRow
          key={date}
          cells={getDatesGridCells(
            date,
            price,
            regularPrice,
            oldPrice,
            oldDate,
            handleRemoveDate,
            isPriceIncreased,
            handleAcceptPrice
          )}
        />
      ))}
    </Collapse>
  );
}

export default DraftDatesRow;
