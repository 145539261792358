import axios from "axios";
import { noop } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { MediaPlanContext } from "../../../context";
import { SponsorshipOption } from "../../../types";
import { prepareMediaPlan } from "../../../utils";
import GridRow from "../../common/GridRow";
import { getSponsorshipGridCells } from "./gridItems";
import SponsorshipOptionDateSelector from "../../calendar/DateSelector";
import DatesRow from "./DatesRow";

type SponsorshipRowProps = {
  sponsorship: SponsorshipOption;
  profileSlug: string;
};

function SponsorshipRow({ sponsorship, profileSlug }: SponsorshipRowProps) {
  const { id, dates } = sponsorship;
  const { setMediaPlan, isBooked } = useContext(MediaPlanContext)!;
  const [isExpanded, setIsExpanded] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const { mediaPlanId } = useParams();

  const handleRemove = async () => {
    try {
      const { data } = await axios.delete(
        `/api/v1/advertiser/media_plans/${mediaPlanId}/destroy_mpso`,
        { params: { so_id: id } }
      );
      setMediaPlan(prepareMediaPlan(data));
    } catch {
      noop();
    }
  };

  const hasDateChanges = useMemo(
    () => sponsorship.dates.some((item) => item.oldDate !== null),
    [sponsorship.dates]
  );
  const hasPriceChanges = useMemo(
    () => sponsorship.dates.some((item) => item.oldPrice !== null),
    [sponsorship.dates]
  );

  const gridCells = getSponsorshipGridCells(
    sponsorship,
    profileSlug,
    isBooked,
    handleRemove,
    setDatePickerOpen,
    isExpanded,
    hasPriceChanges,
    hasDateChanges
  );

  return (
    <>
      <GridRow
        cells={gridCells}
        onClick={
          sponsorship.dates.length > 0
            ? () => setIsExpanded(!isExpanded)
            : undefined
        }
      />
      <DatesRow
        isExpanded={isExpanded}
        dates={dates}
        soId={id}
        regularPrice={sponsorship.regularPricePerUnit}
      />
      <SponsorshipOptionDateSelector
        profileSlug={profileSlug}
        open={datePickerOpen}
        sponsorshipOption={sponsorship}
        closeDatePicker={() => setDatePickerOpen(false)}
        expandRow={() => setIsExpanded(true)}
      />
    </>
  );
}

export default SponsorshipRow;
