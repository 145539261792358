import { Divider } from "@mui/material";
import { useContext, useMemo } from "react";
import MediaPlanLayout from "..";
import { MediaPlanContext } from "../../../context";
import { Profile, SponsorshipOption } from "../../../types";
import GridRow from "../../common/GridRow";
import { getProfileGridItems, getSponsorshipGridItems } from "./gridItems";

const headerConfig = [
  { label: "Newsletter and Placement", xs: 5.5 },
  { label: "Run Date", xs: 2.5, textAlign: "center" },
  { label: "Price", xs: 2, textAlign: "center" },
  { label: "Review/Details", xs: 2, textAlign: "center", borderRight: false },
];

type ProfileRowProps = {
  profile: Profile;
};

function ProfileRow({ profile }: ProfileRowProps) {
  const totalProfilePrice = useMemo(
    () =>
      profile.sponsorshipOptions.reduce(
        (acc: number, curr: SponsorshipOption) => acc + curr.price,
        0
      ),
    [profile.sponsorshipOptions]
  );

  const gridItems = getProfileGridItems(profile, totalProfilePrice);

  return (
    <>
      <GridRow items={gridItems} key={profile.id} />
      {profile.sponsorshipOptions.map((option) =>
        option.sponsorships.map((sponsorship) => (
          <GridRow
            key={sponsorship.id}
            items={getSponsorshipGridItems(sponsorship, option.name)}
          />
        ))
      )}
      <Divider />
    </>
  );
}

function CompletedMediaPlan() {
  const { profiles, totalPrice } = useContext(MediaPlanContext)!;

  const subscribersCount = profiles.reduce(
    (acc, profile) => acc + (profile.subscribers || 0),
    0
  );

  return (
    <MediaPlanLayout
      headerConfig={headerConfig}
      profiles={profiles}
      totalPrice={totalPrice}
      renderRow={(profile) => <ProfileRow profile={profile} />}
      withDivider={false}
      subscribersCount={subscribersCount}
    />
  );
}

export default CompletedMediaPlan;
