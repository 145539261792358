import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Collapse, Grid, GridProps, SxProps, Theme } from "@mui/material";
import React, { ReactNode, useState } from "react";

export interface GridCell {
  id?: string;
  xs: number;
  md?: number;
  content: ReactNode;
  sx?: SxProps<Theme>;
  textAlign?: GridProps["textAlign"];
  justifyContent?: GridProps["justifyContent"];
  display?: "flex" | "block" | "none";
  alignItems?: "center" | "flex-start" | "flex-end";
}

interface GridRowProps {
  items?: GridCell[];
  cells?: GridCell[];
  onClick?: () => void;
  hoverEffect?: boolean;
  containerSx?: SxProps<Theme>;
  // Expandable features
  expandable?: boolean;
  expandedContent?: ReactNode;
  defaultExpanded?: boolean;
}

const styles = {
  row: {
    p: 2,
    "&:hover": { bgcolor: "#F8FAFF" },
    "&:hover .action-close-btn, &:hover .sponsorship-info": {
      opacity: 1,
    },
    height: "72px",
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
  },
  centerGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const GridRow: React.FC<GridRowProps> = ({
  items,
  cells,
  onClick,
  hoverEffect = true,
  containerSx,
  expandable = false,
  expandedContent,
  defaultExpanded = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const gridCells = cells || items || [];

  const handleClick = (e: React.MouseEvent) => {
    if (expandable) {
      setIsExpanded((prev) => !prev);
    }
    onClick?.();
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{
          ...(hoverEffect ? styles.row : {}),
          ...containerSx,
          cursor: expandable || onClick ? "pointer" : "default",
        }}
        onClick={handleClick}
      >
        {gridCells.map((cell, index) => (
          <Grid
            key={cell.id || index}
            item
            xs={cell.xs}
            md={cell.md}
            sx={cell.sx}
            textAlign={cell.textAlign}
            justifyContent={cell.justifyContent}
            display={cell.display}
            alignItems={cell.alignItems}
          >
            {cell.content}
          </Grid>
        ))}
        {expandable && expandedContent && (
          <Grid item xs={2} textAlign="end">
            {isExpanded ? (
              <KeyboardArrowUp sx={{ color: "grey.500" }} />
            ) : (
              <KeyboardArrowDown sx={{ color: "grey.500" }} />
            )}
          </Grid>
        )}
      </Grid>
      {expandable && expandedContent && (
        <Collapse in={isExpanded}>{expandedContent}</Collapse>
      )}
    </>
  );
};

export default GridRow;
