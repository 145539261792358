// TODO: Move to shared-components package
export function currencyFormatter(value: number, precision: number = 2) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  }).format(value);
}

export function getSecondsString(value: number) {
  if (value === 1) {
    return "1 second";
  }

  return `${value} seconds`;
}
