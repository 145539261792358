import { EditOutlined } from "@mui/icons-material";
import { CircularProgress, InputAdornment } from "@mui/material";
import axios from "axios";
import { TextField } from "components/MUIWrappers";
import { noop } from "lodash";
import { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button } from "shared-components";
import { MediaPlanContext } from "../../../context";
import { prepareMediaPlan } from "../../../utils";
import { grey } from "@mui/material/colors";
import { parse, differenceInSeconds } from "date-fns";

const DATE_FORMAT_REGEX = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}$/;

function MediaPlanNameTextfield() {
  const { mediaPlanId } = useParams();
  const { setMediaPlan, name, isBooked } = useContext(MediaPlanContext)!;
  const [newName, setNewName] = useState(name);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const isChanged = newName !== name;

  useEffect(() => {
    if (!DATE_FORMAT_REGEX.test(name)) {
      return;
    }

    try {
      const date = parse(name, "MM/dd/yyyy HH:mm:ss", new Date());
      const diffInSeconds = differenceInSeconds(new Date(), date);
      if (!isNaN(date.getTime()) && diffInSeconds <= 5) {
        inputRef.current?.focus();
      }
    } catch (e) {}
  }, [name]);

  const MAX_NAME_LENGTH = 100;

  const isTooLong = newName.length > MAX_NAME_LENGTH;
  const hasError = !newName || isTooLong;

  const handleUpdate = async () => {
    if (hasError) return;

    try {
      setIsUpdating(true);
      const response = await axios.patch(
        `/api/v1/advertiser/media_plans/${mediaPlanId}`,
        { name: newName }
      );
      setMediaPlan(prepareMediaPlan(response.data));
      setIsUpdating(false);
    } catch (err) {
      noop();
    } finally {
      setIsFocused(false);
    }
  };

  return (
    <TextField
      inputRef={inputRef}
      fullWidth
      size="small"
      variant="standard"
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        p: 0,
        "& ::before": {
          borderBottomColor: `transparent !important`,
          borderWidth: "2px !important",
        },
        "&:hover .MuiInputBase-root:before": {
          borderBottomColor: `${grey[300]} !important`,
        },
      }}
      error={hasError}
      onKeyDown={(e) => {
        if (e.key === "Enter" && isChanged && !isUpdating && !hasError) {
          e.preventDefault();
          handleUpdate();
        }
      }}
      helperText={
        isTooLong
          ? `Name cannot exceed ${MAX_NAME_LENGTH} characters`
          : !newName
          ? "Name is required"
          : ""
      }
      InputProps={{
        disableUnderline: isBooked,
        readOnly: isBooked,
        endAdornment: (isHovered || isFocused) && (
          <InputAdornment position="end">
            {isChanged && newName && (
              <Button
                size="small"
                onClick={handleUpdate}
                disabled={isUpdating || hasError}
              >
                {isUpdating ? <CircularProgress size={20} /> : "Save"}
              </Button>
            )}
            {!isChanged && !isBooked && (
              <EditOutlined color={isFocused ? "primary" : "disabled"} />
            )}
          </InputAdornment>
        ),
        sx: {
          "& .MuiInputBase-input": {
            p: 0,
            fontSize: "24px",
            fontWeight: 700,
            minWidth: "auto",
            color: "text.primary",
          },
        },
      }}
      onChange={(e) => setNewName(e.target.value)}
      value={newName}
    />
  );
}

export default MediaPlanNameTextfield;
