import React from "react";
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from "@mui/material";

export type Breadcrumb = {
  label: string;
  href?: string;
};

export type BreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
};

export default function Breadcrumbs({ breadcrumbs }: BreadcrumbsProps) {
  return (
    <MUIBreadcrumbs
      aria-label="breadcrumb"
      sx={{
        backgroundColor: "transparent",
        p: "5px 0",
        m: 0,
        fontSize: "14px",
        fontWeight: 400,
      }}
    >
      {breadcrumbs.map(({ label, href }, index) => {
        return href ? (
          <Link
            underline="none"
            sx={{
              color: "#9E9E9E",
              ":hover": { color: "#fff" },
            }}
            href={href}
            key={label}
          >
            {label}
          </Link>
        ) : (
          <Typography
            sx={{
              color: "#fff",
              fontSize: "14px",
              fontWeight: 400,
            }}
            key={label}
          >
            {label}
          </Typography>
        );
      })}
    </MUIBreadcrumbs>
  );
}
