import {
  ErrorOutlineOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { Avatar, Stack, Typography } from "@mui/material";
import Tooltip from "components/Tooltip";
import { format } from "date-fns";
import { Profile, SponsorshipOption } from "../../../types";
import { GridCell } from "../../common/GridRow";
import { PriceDisplay } from "../../common/PriceDisplay";
import { AddButton, InfoIcon, RemoveButton, AcceptButton } from "./buttons";
import { currencyFormatter } from "utils/formatters";
import SubscribersQty from "../../common/SubscribersQty";

const styles = {
  name: {
    fontWeight: "bold",
  },
  profileName: {
    cursor: "pointer",
    color: "primary.main",
  },
  avatar: {
    width: 28,
    height: 28,
  },
};

export const getProfileGridCells = (
  profile: Profile,
  onRemove: (profile: Profile) => void
): GridCell[] => [
  {
    id: `avatar-${profile.slug}`,
    xs: 12,
    md: 0.5,
    textAlign: "right",
    content: (
      <Avatar src={profile.logo} variant="circular" sx={styles.avatar} />
    ),
  },
  {
    id: `name-${profile.slug}`,
    xs: 11.75,
    md: 11.25,
    content: (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography
          noWrap
          fontWeight="bold"
          sx={styles.profileName}
          onClick={(e) => {
            e.preventDefault();
            window.open(`/sites/${profile.slug}`, "_blank");
          }}
        >
          {profile.name}
        </Typography>
        <SubscribersQty subscribers={profile.subscribers} />
      </Stack>
    ),
  },
  {
    id: `remove-${profile.slug}`,
    xs: 0.25,
    md: 0.25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    content: <RemoveButton onClick={() => onRemove(profile)} />,
  },
];

export const getSponsorshipGridCells = (
  sponsorship: SponsorshipOption,
  profileSlug: string,
  isBooked: boolean,
  onRemove: () => void,
  setDatePickerOpen: (open: boolean) => void,
  isExpanded: boolean,
  hasPriceChanges: boolean,
  hasDateChanges: boolean
): GridCell[] => [
  {
    id: `info-${sponsorship.uuid}`,
    xs: 0.5,
    content: <InfoIcon description={sponsorship.description} />,
    sx: { display: "flex", justifyContent: "center", alignItems: "center" },
  },
  {
    id: `name-${sponsorship.uuid}`,
    xs: 3.5,
    content: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography
          noWrap
          sx={{
            ...styles.name,
            cursor: isBooked ? "default" : "pointer",
            maxWidth: "fit-content",
          }}
          onClick={(e) => {
            e.stopPropagation();
            !isBooked &&
              window.open(
                `/sites/${profileSlug}#${sponsorship.uuid}`,
                "_blank"
              );
          }}
        >
          {sponsorship.name}
        </Typography>
        {(hasPriceChanges || hasDateChanges) && (
          <Tooltip
            title={
              hasPriceChanges
                ? `Price increase detected`
                : `Date change detected`
            }
            disableInteractive
            arrow
            enterTouchDelay={0}
            leaveTouchDelay={10000}
          >
            <ErrorOutlineOutlined
              fontSize="small"
              color={hasPriceChanges ? "warning" : "info"}
            />
          </Tooltip>
        )}
      </Stack>
    ),
  },
  {
    id: `qty-${sponsorship.uuid}`,
    xs: 1.5,
    content: <Typography>{sponsorship.quantity}</Typography>,
    textAlign: "center",
  },
  {
    id: `rate-${sponsorship.uuid}`,
    xs: 1.5,
    content: <Typography>{sponsorship.clickRate || "-"}</Typography>,
    textAlign: "center",
  },
  {
    id: `cpc-${sponsorship.uuid}`,
    xs: 1.5,
    content: (
      <Typography>{sponsorship.cpc ? `$${sponsorship.cpc}` : "-"}</Typography>
    ),
    textAlign: "center",
  },
  {
    id: `price-${sponsorship.uuid}`,
    xs: 2,
    content: (
      <PriceDisplay
        price={sponsorship.price}
        priceWithoutDiscount={
          // TODO: check if this is correct
          sponsorship.regularPricePerUnit * sponsorship.dates.length
        }
        bold
      />
    ),
    sx: { display: "flex", alignItems: "center" },
    justifyContent: "flex-end",
  },
  {
    id: `actions-${sponsorship.uuid}`,
    xs: 1.25,
    content: (
      <Stack
        px={2}
        direction="row"
        alignItems="center"
        justifyContent={
          !!sponsorship.dates.length ? "space-between" : "flex-start"
        }
      >
        <AddButton isBooked={isBooked} onAdd={() => setDatePickerOpen(true)} />
        {sponsorship.dates.length > 0 && (
          <Stack color="grey.500">
            {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Stack>
        )}
      </Stack>
    ),
  },
  {
    id: `remove-${sponsorship.uuid}`,
    xs: 0.25,
    content: !isBooked && <RemoveButton onClick={onRemove} />,
    sx: { display: "flex", justifyContent: "center", alignItems: "center" },
  },
];

export const getDatesGridCells = (
  date: string,
  price: number,
  regularPrice: number,
  oldPrice: number | null,
  oldDate: string | null,
  onRemoveDate: (date: string) => void,
  isPriceIncreased: (price: number, oldPrice: number | null) => boolean,
  handleAcceptPrice: (date: string) => Promise<any>
): GridCell[] => [
  {
    id: `spacer-${date}`,
    xs: 0.75,
    content: null,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  {
    id: `weekday-${date}`,
    xs: 0.5,
    content: <Typography>{format(new Date(date), "EEE,")}</Typography>,
  },
  {
    id: `date-${date}`,
    xs: 7.25,
    content: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{format(new Date(date), "do MMMM yyyy")}</Typography>
        <>
          {isPriceIncreased(price, oldPrice) ? (
            <Tooltip
              title={`Price increased from ${currencyFormatter(
                oldPrice as number,
                0
              )} to ${currencyFormatter(
                price as number,
                0
              )} since your last visit.`}
              disableInteractive
              arrow
              enterTouchDelay={0}
              leaveTouchDelay={10000}
            >
              <ErrorOutlineOutlined fontSize="small" color="warning" />
            </Tooltip>
          ) : (
            !!oldDate && (
              <Tooltip
                title={`Previous date (${format(
                  new Date(oldDate),
                  "do MMMM yyyy"
                )}) is unavailable and has been automatically updated.`}
                disableInteractive
                arrow
                enterTouchDelay={0}
                leaveTouchDelay={10000}
              >
                <ErrorOutlineOutlined fontSize="small" color="info" />
              </Tooltip>
            )
          )}
        </>
      </Stack>
    ),
    display: "flex",
    alignItems: "center",
  },
  {
    id: `price-${date}`,
    xs: 2,
    content: (
      <PriceDisplay
        price={price}
        priceWithoutDiscount={regularPrice}
        oldPrice={oldPrice}
      />
    ),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  {
    id: `spacer2-${date}`,
    xs: 1.25,
    content: isPriceIncreased(price, oldPrice) ? (
      <AcceptButton onClick={() => handleAcceptPrice(date)} />
    ) : null,
  },
  {
    id: `remove-${date}`,
    xs: 0.25,
    content: <RemoveButton onClick={() => onRemoveDate(date)} />,
    display: "flex",
    justifyContent: "center",
  },
];
