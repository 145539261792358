import { useContext } from "react";
import { MediaPlanContext } from "../../../context";
import InProgressProfileRow from "./ProfileRow";
import MediaPlanLayout from "..";
const headerConfig = [
  { label: "Newsletter and Placement", xs: 5, pl: "12px" },
  { label: "Status", xs: 1.5, textAlign: "center" },
  { label: "Deadline", xs: 1.5, textAlign: "center" },
  { label: "Price", xs: 2, textAlign: "center" },
  { label: "Action Required", xs: 2, textAlign: "center", borderRight: false },
];

function InProgressMediaPlan() {
  const { profiles, totalPrice } = useContext(MediaPlanContext)!;
  const subscribersCount = profiles.reduce(
    (acc, profile) => acc + (profile.subscribers || 0),
    0
  );
  return (
    <MediaPlanLayout
      headerConfig={headerConfig}
      profiles={profiles}
      totalPrice={totalPrice}
      renderRow={(profile) => <InProgressProfileRow profile={profile} />}
      subscribersCount={subscribersCount}
    />
  );
}

export default InProgressMediaPlan;
