import { Divider } from "@mui/material";
import { Profile } from "../../../types";
import GridRow from "../../common/GridRow";
import { getProfileRowCells } from "./gridItems";
import SponsorshipRow from "./SponsorshipRow";

function ProfileRow({ profile }: { profile: Profile }) {
  return (
    <>
      <GridRow
        expandable
        cells={getProfileRowCells(profile)}
        expandedContent={
          <>
            {profile.sponsorshipOptions.map((option) => (
              <SponsorshipRow key={option.id} sponsorshipOptions={option} />
            ))}
          </>
        }
      />
      <Divider />
    </>
  );
}

export default ProfileRow;
