import { MoreVert } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { MouseEvent, useState } from "react";
import { Button, Icon } from "shared-components";

interface MediaPlanMenuProps {
  mediaPlanId: string;
  name: string;
  onInvite: () => void;
  onDeleteMediaPlan: () => void;
}

const MediaPlanMenu: React.FC<MediaPlanMenuProps> = ({
  mediaPlanId,
  name,
  onInvite,
  onDeleteMediaPlan,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [isLoading, setIsLoading] = useState(false);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const withMenuClose = (action: () => void | Promise<void>) => async () => {
    handleMenuClose();
    await action();
  };

  // const downloadFile = async (url: string, downloadName: string) => {
  //   setIsLoading(true);
  //   try {
  //     const { data } = await axios({
  //       url,
  //       method: "GET",
  //       responseType: "blob",
  //     });
  //     const blobUrl = window.URL.createObjectURL(new Blob([data]));
  //     const link = document.createElement("a");
  //     link.href = blobUrl;
  //     link.setAttribute("download", downloadName);
  //     document.body.appendChild(link);
  //     link.click();
  //   } catch (error) {
  //     noop();
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const handleDownloadCreativeRequirements = () =>
  //   downloadFile(
  //     `/api/v1/advertiser/media_plans/${mediaPlanId}/download_creative_requirements`,
  //     `${name}_creative_requirements.pdf`
  //   );

  // const handleExportMediaPlan = () =>
  //   downloadFile(
  //     `/api/v1/advertiser/media_plans/${mediaPlanId}/download_media_plan`,
  //     `${name}.pdf`
  //   );

  const menuItems = [
    {
      icon: <Icon name="invite" width={20} height={20} />,
      text: "Invite",
      onClick: () => onInvite(),
    },
    // TEMPORARILY DISABLED
    // {
    //   icon: <Icon name="listAltCheck" width={20} height={20} />,
    //   text: "Download Creative Requirements",
    //   onClick: handleDownloadCreativeRequirements,
    // },
    // {
    //   icon: <Icon name="fileSave" width={20} height={20} />,
    //   text: "Export Media Plan",
    //   onClick: handleExportMediaPlan,
    // },
    {
      icon: <Icon name="trashCan" width={20} height={20} />,
      text: "Delete Media Plan",
      onClick: () => onDeleteMediaPlan(),
    },
  ];

  return (
    <>
      <Button
        sx={{ minWidth: 40, width: 40, p: 0 }}
        variant="outlined"
        onClick={handleMenuOpen}
        // disabled={isLoading}
      >
        <MoreVert />
        {/* {isLoading ? <CircularProgress size={20} /> : <MoreVert />} */}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{ sx: { borderRadius: "8px" } }}
      >
        {menuItems.map(({ icon, text, onClick }) => (
          <MenuItem key={text} onClick={withMenuClose(onClick)}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MediaPlanMenu;
